<template>
    <div class="container pt-4" style="max-width: 80%">
        <b-card header-tag="header" >
            <template #header>
                <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">Articulos para producto: {{producto ? producto.sku : ''}}</div>
            </template>
            
            <div v-if="loading" class="d-flex justify-content-center mt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div v-else>
                <!-- Datos Producto -->
                <b-card v-if="producto" header-tag="header" class="datos-info mb-2">
                    <template #header>
                        <div class="table-panel-title">Datos Producto</div>
                    </template>

                    <div>
                        <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                            <!-- Imagenes -->
                            <div style="width: 100px; padding: 10px; background-color: #fff; border: 1px solid #dee2e6; border-radius: 0.25rem;" class="text-center">
                                <template> 
                                    <img 
                                        :src="getUrlImg(producto.mini_img)" 
                                        :alt="producto.titulo" 
                                        style="max-width: 80px; height: 80px;"
                                        onerror="javascript:this.src='https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png'"
                                    />
                                </template> 
                            </div>
                            <!-- Datos Producto -->
                            <div class="row" style="flex-grow: 1; padding: 1em;">
                                <div class="col-4 d-flex flex-column datos-item" >
                                    <span class="mb-2"><b>ID: </b>{{ producto.id }}</span>
                                    <span class="mb-1"><b>Marca: </b>{{ producto.marca }}</span>
                                    <span class="mb-1"><b>Modelo: </b>{{ producto.modelo }}</span>
                                    <span class="mb-1"><b>SKU: </b>{{ producto.sku }}</span>
                                </div>
                                <div class="col-4 d-flex flex-column datos-item" >
                                    <span class="mb-1"><b>Articulo Selected: </b>{{ producto.ProductoFlag.id_articulo_selected ? producto.ProductoFlag.id_articulo_selected : 'N/A' }}</span>
                                    <span class="mb-1"><b>Disponible: </b>{{ producto.disponible ? 'Si' : 'No' }}</span>
                                </div>
                                <!-- BTNs -->
                                <div class="col-4 d-flex flex-column text-right">
                                    <b-button variant="primary" @click="showModalBuscarArticulo()">
                                        <b-icon icon="search" aria-hidden="true" class="mr-1"></b-icon>
                                        Buscar Articulo
                                    </b-button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </b-card>

                <!-- Articulos Relacionados -->
                <b-card v-if="articulosRelacionados.length" header-tag="header" class="datos-info mb-2">
                    <template #header>
                        <div class="float-left table-panel-title">Articulos relacionados</div>
                    </template>

                    <!-- Articulos -->
                    <div>
                        <div 
                            class="d-flex flex-row flex-wrap justify-content-between align-items-center item-row mb-2" 
                            v-for="(articulo, index) in articulosRelacionados" 
                            :key="`articulos-rel-${index}`"
                            :class="{'selected' : checkArticuloSelected(articulo.id)}"
                        >
                            <!-- Imagenes -->
                            <div style="width: 100px; padding: 10px; background-color: #fff; border: 1px solid #dee2e6; border-radius: 0.25rem;" class="text-center">
                                <template> 
                                    <img 
                                        :src="getUrlImg(articulo.mini_img)" 
                                        :alt="articulo.titulo" 
                                        style="max-width: 80px; height: 80px;"
                                        onerror="javascript:this.src='https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png'"
                                    />
                                </template> 
                            </div>
                            <!-- Datos Producto -->
                            <div class="row" style="flex-grow: 1; padding: 1em;">
                                <div class="col-4 d-flex flex-column datos-item" >
                                    <span class="mb-1"><b>ID: </b>
                                        {{ articulo.id }}
                                        <span v-if="articulo.id != id_manual_selected && checkArticuloSelected(articulo.id)"> (Seleccionado)</span>
                                        <span v-if="articulo.id == id_manual_selected"> (Seleccionado Manual)</span>
                                    </span>
                                    <span class="mb-1"><b>Marca: </b>{{ articulo.marca }}</span>
                                    <span class="mb-1"><b>Modelo: </b>{{ articulo.modelo }}</span>
                                    <span class="mb-1"><b>Proveedor: </b>{{ articulo.Proveedor ?  articulo.Proveedor.nombre : 'N/A'}}</span>
                                    <span class="mb-1"><b>SKU: </b>
                                        <a :href="articulo.link_item_proveedor" target="_blank" rel="noopener noreferrer">{{ articulo.sku_proveedor }}</a>
                                    </span>
                                </div>
                                <div class="col-8 datos-item">
                                    <div>
                                        <b-badge class="mr-2 mb-2" :variant="articulo.categorias_proveedor && articulo.categorias_proveedor.length ? 'success' : 'danger'">Categorias ({{articulo.categorias_proveedor ? articulo.categorias_proveedor.length : '0'}})</b-badge>
                                        <b-badge class="mr-2 mb-2" :variant="articulo.imgs.length ? 'success' : 'danger'">Img ({{articulo.imgs.length}})</b-badge>
                                    </div>
                                    <div>
                                        <b-badge class="mr-2 mb-2" variant="danger" v-if="articulo.error">Error</b-badge>
                                        <b-badge class="mr-2 mb-2" variant="danger" v-if="articulo.revisar">Revisar</b-badge>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Action Btn -->
                            <div class="flex flex-col float-right">
                                <b-button 
                                    variant="primary" 
                                    block 
                                    size="sm"
                                    @click="askSetManualSelected(articulo)"
                                    v-if="!id_manual_selected && !articulo.error"
                                >
                                    Manual Select
                                </b-button>
                                <b-button 
                                    variant="primary" 
                                    block 
                                    size="sm"
                                    @click="askSetAutoSelected(articulo)"
                                    v-else-if="articulo.id == id_manual_selected"
                                >
                                    Auto Select
                                </b-button>
                                <b-button 
                                    variant="danger" 
                                    block 
                                    size="sm"
                                    @click="askDesRelacionarArticuloToProducto(articulo)"
                                    v-if="!checkArticuloSelected(articulo.id)"
                                >
                                    Des-Relacionar
                                </b-button>
                            </div>
                        </div>
                    </div>

                </b-card>

                <!-- Posibles Articulos -->
                <b-card v-if="posiblesArticulos.length" header-tag="header" class="datos-info mb-2">
                    <template #header>
                        <div class="float-left table-panel-title">Posibles Articulos relacionados</div>
                    </template>

                    <!-- Articulos -->
                    <div>
                        <div 
                            class="d-flex flex-row flex-wrap justify-content-between align-items-center item-row mb-2" 
                            v-for="(articulo, index) in posiblesArticulos" 
                            :key="`articulos-rel-${index}`"
                        >
                            <!-- Imagenes -->
                            <div 
                                style="width: 100px; padding: 10px; background-color: #fff; border: 1px solid #dee2e6; border-radius: 0.25rem;" 
                                class="text-center"
                            >
                                <template> 
                                    <img 
                                        :src="getUrlImg(articulo.mini_img)" 
                                        :alt="articulo.titulo" 
                                        style="max-width: 80px; height: 80px;"
                                        onerror="javascript:this.src='https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png'"
                                    />
                                </template> 
                            </div>
                            <!-- Datos Producto -->
                            <div class="row" style="flex-grow: 1; padding: 1em;">
                                <div class="col-4 d-flex flex-column datos-item" >
                                    <span class="mb-1"><b>ID: </b>{{ articulo.id }}</span>
                                    <span class="mb-1"><b>Marca: </b>{{ articulo.marca }}</span>
                                    <span class="mb-1"><b>Modelo: </b>{{ articulo.modelo }}</span>
                                    <span class="mb-1"><b>Proveedor: </b>{{ articulo.Proveedor ?  articulo.Proveedor.nombre : 'N/A'}}</span>
                                    <span class="mb-1"><b>SKU: </b>
                                        <a :href="articulo.link_item_proveedor" target="_blank" rel="noopener noreferrer">{{ articulo.sku_proveedor }}</a>
                                    </span>
                                </div>
                                <div class="col-8 datos-item">
                                    <div>
                                        <b-badge class="mr-2 mb-2" :variant="articulo.categorias_proveedor && articulo.categorias_proveedor.length ? 'success' : 'danger'">Categorias ({{articulo.categorias_proveedor ? articulo.categorias_proveedor.length : '0'}})</b-badge>
                                        <b-badge class="mr-2 mb-2" :variant="articulo.imgs.length ? 'success' : 'danger'">Img ({{articulo.imgs.length}})</b-badge>
                                    </div>
                                    <div>
                                        <b-badge class="mr-2 mb-2" variant="danger" v-if="articulo.error">Error</b-badge>
                                        <b-badge class="mr-2 mb-2" variant="danger" v-if="articulo.revisar">Revisar</b-badge>
                                    </div>
                                </div>
                            </div>

                            <!-- Action Btn -->
                            <div class="flex flex-col float-right">
                                <b-button 
                                    variant="primary" 
                                    block 
                                    size="sm"
                                    @click="askRelacionarArticuloToProducto(articulo)"
                                >
                                    Relacionar
                                </b-button>
                                <b-button 
                                    variant="danger" 
                                    block 
                                    size="sm"
                                    @click="askDesvincularPosibleArticuloToProducto(articulo)"
                                >
                                    Desvincular
                                </b-button>
                            </div>
                        </div>
                    </div>

                </b-card>
            </div>
        </b-card>
        <ModalBuscarArticulos ref="modalBuscarArticulos" :refreshArticulos="refreshArticulosRelacionados"></ModalBuscarArticulos>
    </div>
</template>

<script>

import ModalBuscarArticulos from './modals/buscarArticulos.vue';

export default {
    components: {
        ModalBuscarArticulos
    },
    data(){
        return {
            loading: true,
            loadingRelacionar: false,
            id_producto: null,
            producto: null,
            id_manual_selected: null,
            articulosRelacionados: [],
            posiblesArticulos: [],
            marcas: []
        }
    },
    async beforeMount(){
        this.loading = true;
        this.id_producto = this.$route.params.id;
        this.getListaMarcas();
        await this.getProducto();
        await this.getArticulosProducto();
        await this.getPosiblesArticulosProducto();
        this.loading = false;
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        getUrlImg(url){
            if(url) return url;
            return 'https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png';
        },
        async getListaMarcas(){
            try {
                let response = await this.$http.get(`producto/marca`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener lista de marcas');
                this.marcas = [];
                response.body.data.forEach(element => {
                    if(this.marcas.indexOf(element.marca) == -1) this.marcas.push(element.marca);
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getArticulosProducto(){
            try {
                this.articulosRelacionados = [];
                let query = `producto/${this.id_producto}/articulos`;

                let response = await this.$http.get(query, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener articulos');

                this.articulosRelacionados = response.body.data;

                this.id_manual_selected = null;
                this.articulosRelacionados.forEach(element => {
                    if(element.manual_selected) this.id_manual_selected = element.id;
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getPosiblesArticulosProducto(){
            try {
                let query = `producto/${this.id_producto}/posibles-articulos`;

                let response = await this.$http.get(query, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener posibles-articulos');

                this.posiblesArticulos = [];
                if(response.body.data){
                    response.body.data.forEach(element => {
                        let encontrado = this.articulosRelacionados.find( element2 => element.id == element2.id);
                        if(!encontrado) this.posiblesArticulos.push(element);
                    });
                }
                

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getProducto(){
            try {
                let query = `producto/${this.id_producto}`;

                let response = await this.$http.get(query, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener producto');

                this.producto = response.body.data;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        showModalBuscarArticulo(){
            this.$refs.modalBuscarArticulos.showModal(this.marcas,this.posiblesArticulos,this.producto);
        },
        checkArticuloSelected(idArticulo){
            if(idArticulo == parseInt(this.producto.ProductoFlag.id_articulo_selected)) return true;
            return false;
        },
        async refreshArticulosRelacionados(){
            this.loading = true;
            await this.getArticulosProducto();
            await this.getPosiblesArticulosProducto();
            this.loading = false;
        },
        askRelacionarArticuloToProducto(articulo){
              //Verifico si ese articulo ya pertenece a otro producto
              if(articulo.id_producto){
                const swalWithBootstrapButtons = this.$swal.mixin({
                    customClass: {
                        title: "swal-title",
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger mr-3'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Este articulo ya pertenece a un producto, esta seguro de cambiarlo?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Cambiar',
                    cancelButtonText: 'No',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.relacionarArticuloToProducto(articulo);
                        }
                    })
              }
              else{
                this.relacionarArticuloToProducto(articulo);
              }
              
        },
        async relacionarArticuloToProducto(articulo){
            try {
                this.loadingRelacionar = true;
                let response = await this.$http.put(`articulo/${articulo.id}/relacion-producto`,{id_producto: this.producto.id}, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al relacionar articulo');
                
                this.$swal.fire({
                    icon: 'success',
                    title: 'Articulo Relacionado',
                    timer: 1500
                });

                this.refreshArticulosRelacionados();
                this.loadingRelacionar = false;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                console.log(message)
                this.loadingRelacionar = false;
            }
        },
        askDesRelacionarArticuloToProducto(articulo){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro de eliminar la relacion de este articulo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Eliminar',
                cancelButtonText: 'No',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.desRelacionarArticuloToProducto(articulo);
                    }
                })
              
        },
        async desRelacionarArticuloToProducto(articulo){
            try {
                this.loadingRelacionar = true;
                let response = await this.$http.delete(`articulo/${articulo.id}/relacion-producto/${this.producto.id}`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al des relacionar articulo');
                
                this.$swal.fire({
                    icon: 'success',
                    title: 'Articulo Des-Relacionado',
                    timer: 1500
                });
                
                this.refreshArticulosRelacionados();
                this.loadingRelacionar = false;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                console.log(message)
                this.loadingRelacionar = false;
            }
        },
        askDesvincularPosibleArticuloToProducto(articulo){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro de desvincular este articulo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Desvincular',
                cancelButtonText: 'No',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.desvincularPosibleArticuloToProducto(articulo);
                    }
                })
              
        },
        async desvincularPosibleArticuloToProducto(articulo){
            try {
                this.loadingRelacionar = true;
                let response = await this.$http.delete(`articulo/${articulo.id}/posible-relacion-producto/${this.producto.id}`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al des relacionar articulo');
                
                this.$swal.fire({
                    icon: 'success',
                    title: 'Articulo Desvinculado',
                    timer: 1500
                });

                this.refreshArticulosRelacionados();
                this.loadingRelacionar = false;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                console.log(message)
                this.loadingRelacionar = false;
            }
        },
        askSetManualSelected(articulo){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro forzar seleccion manual de este articulo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Seleccionar',
                cancelButtonText: 'No',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.setManualSelected(articulo);
                    }
                })
              
        },
        async setManualSelected(articulo){
            try {
                this.loadingRelacionar = true;
                let response = await this.$http.put(`articulo/${articulo.id}/set-manual-select`, {id_producto: this.producto.id}, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al forzar seleccion manual del articulo');
                
                this.$swal.fire({
                    icon: 'success',
                    title: 'Articulo seteado manualmente como selected',
                    timer: 1500
                });

                await this.getProducto();
                this.refreshArticulosRelacionados();
                this.loadingRelacionar = false;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                console.log(message)
                this.loadingRelacionar = false;
            }
        },
        askSetAutoSelected(articulo){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro forzar seleccion automatica de articulos?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Seleccionar',
                cancelButtonText: 'No',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.setAutoSelected(articulo);
                    }
                })
              
        },
        async setAutoSelected(articulo){
            try {
                this.loadingRelacionar = true;
                let response = await this.$http.put(`articulo/${articulo.id}/set-auto-select`, {id_producto: this.producto.id}, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al forzar seleccion auto de articulos');
                
                this.$swal.fire({
                    icon: 'success',
                    title: 'Articulos seleccionados automaticamente',
                    timer: 1500
                });

                await this.getProducto();
                this.refreshArticulosRelacionados();
                this.loadingRelacionar = false;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                console.log(message)
                this.loadingRelacionar = false;
            }
        },
    }
}
</script>

<style>
    .datos-info > .card-header{
        padding: 5px 15px;
    }
    .datos-info > .card-header > .table-panel-title {
        line-height: 20px;
        font-size: 12px;
        font-weight: 700;
    }
    .item-row{
        border: 1px solid rgb(0 0 0 / 25%);
        padding: 10px 15px;
        border-radius: 5px;
    }
    .datos-item{
        font-size: small;
    }
    .selected{
        background-color: #a4a8d793;
    }
</style>