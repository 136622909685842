<template>
    <div>
        <b-card header-tag="header" >
            <template #header>
                <b-button class="float-left mr-3" @click="$router.go(-1)" variant="danger" size="sm">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">
                    Productos <span v-if="!loading">[ {{pagination.count}} ]</span>
                </div>

                <!-- Filtros -->
                <b-button class="float-right mr-3" @click="displayFiltros = !displayFiltros" size="sm" variant="secondary">
                        Filtros
                </b-button>
                <div class="clearfix"></div>
                <b-collapse id="collapse-4" v-model="displayFiltros" class="mt-4">
                    <div class="row">
                        <!-- KeyWord -->
                        <div class="col-sm-12 col-lg-4 mb-3">
                            <b-input-group prepend="Keyword" size='sm'>
                                <b-form-input
                                    type="text" 
                                    v-model.trim="filtros.keyword"
                                    class="form-control" 
                                    :disabled = "loading"
                                ></b-form-input>
                            </b-input-group>
                        </div>
                        <!-- Marca -->
                        <div class="col-sm-12 col-lg-4 mb-3">
                            <b-input-group prepend="Marca" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.marca"
                                    :options="marcas"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                    <template #first>
                                        <b-form-select-option value="">Todas</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- Sku -->
                        <div class="col-sm-12 col-lg-4 mb-3">
                            <b-input-group prepend="SKU" size='sm'>
                                <b-form-input
                                    type="text" 
                                    v-model.trim="filtros.sku"
                                    class="form-control" 
                                    :disabled = "loading"
                                ></b-form-input>
                            </b-input-group>
                        </div>
                        <!-- medidas -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Medidas" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.medidas"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- pesos -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Pesos" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.pesos"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- img -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Imagenes" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.img"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- video -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Videos" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.video"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- manual -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Manual" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.manual"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- attr -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Attr" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.attr"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- codigo -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Codigos" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.codigo"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- disponible -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Disponible" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.disponible"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- relevado -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Relevado" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.relevado"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- Has articulo selected -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Tiene Articulo" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.has_articulo_selected"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- pendientes -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Pendiente" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.pendiente"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- not_wanted -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Deseados" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.not_wanted"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                    <b-form-select-option value="TODO">Todos</b-form-select-option>
                                    <b-form-select-option value="false">Si</b-form-select-option>
                                    <b-form-select-option value="true">No</b-form-select-option>
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- Cable,Bateria,Trafo -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Electricos" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.electricos"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- desc_es -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Desc. ES" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.desc_es"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- Publicar ML -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Publicar ML" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.publicar_ml"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- Publicar WC -->
                        <div class="col-sm-6 col-lg-3 mb-3">
                            <b-input-group prepend="Publicar WC" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.publicar_wc"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                                        

                        <div class="col-12">
                            <b-button class="float-right mb-2" size="sm" @click="aplicarFiltros()" variant="primary">
                                Aplicar
                            </b-button>
                        </div>
                    </div>
                    
                </b-collapse>

            </template>

            <div v-if="loading" class="d-flex justify-content-center mt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div v-else class="mb-3">
                <div class="d-flex flex-row flex-wrap justify-content-between align-items-center item-row mb-2" v-for="(producto, index) in productos" :key="index">
                    <!-- Imagenes -->
                    <div style="width: 220px; padding: 10px; background-color: #fff; border: 1px solid #dee2e6; border-radius: 0.25rem;" class="text-center">
                        <template> 
                            <img 
                                @click="openModalImagenes(producto.id)"
                                :src="getUrlImg(producto.mini_img)" 
                                class="pointer" 
                                :alt="producto.titulo" 
                                style="max-width: 200px; height: 200px;"
                                onerror="javascript:this.src='https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png'"
                            />
                        </template> 
                    </div>
                    <!-- Datos Producto -->
                    <div class="row" style="flex-grow: 1; padding: 2em;">
                        <div class="col-4 d-flex flex-column datos-item" >
                            <span class="mb-2"><b>ID: </b>{{ producto.id }}</span>
                            <span class="mb-2"><b>Marca: </b>{{ producto.marca }}</span>
                            <span class="mb-2"><b>Modelo: </b>{{ producto.modelo }}</span>
                            <span class="mb-2"><b>SKU: </b>{{ producto.sku }}</span>
                            <span class="mb-2"><b>Art. Selected: </b>{{ producto.ProductoFlag && producto.ProductoFlag.id_articulo_selected ? producto.ProductoFlag.id_articulo_selected : '-' }}</span>
                        </div>
                        <div class="col-8 datos-item" v-if="producto.ProductoFlag">
                            <div>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.relevado ? 'success' : 'danger'">Relevado<span v-if="!producto.ProductoFlag.relevado && producto.ProductoFlag.pendiente"> (Pendiente)</span></b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.disponible ? 'success' : 'danger'">Disponible</b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.count_articulos ? 'success' : 'danger'">Articulos ({{producto.ProductoFlag.count_articulos}})</b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.desc_es ? 'success' : 'danger'">Descripcion</b-badge>
                            </div>
                            <div>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.publicar_ml ? 'success' : 'danger'">
                                    Publicar ML <span v-if="producto.ProductoFlag.force_status_ml"> (Force)</span>
                                </b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.publicar_wc ? 'success' : 'danger'">
                                    Publicar WC <span v-if="producto.ProductoFlag.force_status_wc"> (Force)</span>
                                </b-badge>
                            </div>
                            <div>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.not_wanted ? 'success' : 'danger'">
                                    <span v-if="!producto.ProductoFlag.not_wanted">Deseado</span>
                                    <span v-if="producto.ProductoFlag.not_wanted">No Deseado</span>
                                </b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.pendiente ? 'success' : 'danger'">Pendiente</b-badge>
                            </div>
                            <div>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.baterias ? 'success' : 'danger'">Baterias</b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.cable ? 'success' : 'danger'">Cable</b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.trafo ? 'success' : 'danger'">Trafo</b-badge>
                            </div>
                            <div>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.count_medidas ? 'success' : 'danger'">Medidas ({{producto.ProductoFlag.count_medidas}})</b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.count_pesos ? 'success' : 'danger'">Pesos ({{producto.ProductoFlag.count_pesos}})</b-badge> 
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.count_imgs ? 'success' : 'danger'">Imgs ({{producto.ProductoFlag.count_imgs}})</b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.count_videos ? 'success' : 'danger'">Videos ({{producto.ProductoFlag.count_videos}})</b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.count_manual ? 'success' : 'danger'">Manual ({{producto.ProductoFlag.count_manual}})</b-badge>
                                <b-badge class="mr-2 mb-2" :variant="producto.ProductoFlag.count_codigos ? 'success' : 'danger'">Codigos ({{producto.ProductoFlag.count_codigos}})</b-badge>
                            </div>         
                        </div>
                    </div>
                    <!-- Action Btn -->
                    <div class="align-self-start">
                        <template>
                            <b-dropdown variant="outline-ligth" class="card-dropdown">
                                <b-dropdown-item variant="info" >
                                    <b-icon icon="eye" aria-hidden="true"></b-icon>
                                    Ver
                                </b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item variant="warning"  >
                                    <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                                    Editar
                                </b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item variant="primary" @click="articulosRelacionados(producto.id)">
                                    <b-icon icon="search" aria-hidden="true"></b-icon>
                                    Articulos Rel.
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </div>
                </div>
                
                <b-pagination
                    v-model="pagination.page"
                    :total-rows="pagination.count"
                    :per-page="pagination.limit"
                    align="center"
                    first-number
                    last-number
                    @change="handlePageChange"
                ></b-pagination>

            </div>
        </b-card>
        <ModalGaleriaImagenes ref="modalGaleriaImages"></ModalGaleriaImagenes>
    </div>  
</template>

<script>
import ModalGaleriaImagenes from './modals/galeriaImagenes.vue';

export default {
    components: {
        ModalGaleriaImagenes
    },
    data(){
        return {
            loading: true,
            displayFiltros : false,
            showModalImgs: false,
            booleanOpts: [{value: 'TODO', text: "Todos"},{value: false, text: "No"},{value: true, text: "Si"}],
            marcas: [],
            filtros: {
                sku: '',
                marca: '',
                keyword: '',
                medidas: 'TODO',
                pesos: 'TODO',
                img: 'TODO',
                video: 'TODO',
                manual: 'TODO',
                attr: 'TODO',
                codigo: 'TODO',
                disponible: 'TODO',
                relevado: 'TODO',
                pendiente: 'TODO',
                not_wanted: 'TODO',
                has_articulo_selected: 'TODO',
                electricos: 'TODO',
                desc_es: 'TODO',
                publicar_ml: 'TODO',
                publicar_wc: 'TODO'
            },
            pagination:{
                page: 1,
                limit: 10,
                count: 0,
            },
            productos: [],
            imagenesAux: [],
            productoSelect : null
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        getUrlImg(url){
            if(url) return url;
            return 'https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png';
        },
        async getListaProductos(){
            try {
                let query = `producto/admin`+this.armarQuery(false);

                let response = await this.$http.get(query, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener productos');

                console.log('Productos: ',response.body.data);
                this.productos = response.body.data.rows;

                this.pagination.page = response.body.data.page;
                this.pagination.limit = response.body.data.limit;
                this.pagination.count = response.body.data.count;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getListaMarcas(){
            try {
                let response = await this.$http.get(`producto/marca`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener lista de marcas');
                this.marcas = [];
                console.log('Marcas: ',response.body.data);
                response.body.data.forEach(element => {
                    if(this.marcas.indexOf(element.marca) == -1) this.marcas.push(element.marca);
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async handlePageChange(value){
            this.loading = true;
            this.pagination.page = value;
            this.armarQuery();
            await this.getListaProductos();
            this.loading = false;
        },
        async aplicarFiltros (){
            this.loading = true;
            this.pagination.page = 1;
            await this.getListaProductos();
            this.loading = false;
        },
        armarQuery(){
            let queryParams = {};
            queryParams.page = this.pagination.page;
            queryParams.limit = this.pagination.limit;
            if(this.filtros.sku != '')    queryParams.sku = this.filtros.sku;
            if(this.filtros.keyword != '')    queryParams.keyword = this.filtros.keyword;
            if(this.filtros.marca != '')    queryParams.marca = this.filtros.marca;
            if(this.filtros.medidas != 'TODO')    queryParams.medidas = this.filtros.medidas;
            if(this.filtros.pesos != 'TODO')    queryParams.pesos = this.filtros.pesos;
            if(this.filtros.img != 'TODO')    queryParams.img = this.filtros.img;
            if(this.filtros.video != 'TODO')    queryParams.video = this.filtros.video;
            if(this.filtros.manual != 'TODO')    queryParams.manual = this.filtros.manual;
            if(this.filtros.attr != 'TODO')    queryParams.attr = this.filtros.attr;
            if(this.filtros.codigo != 'TODO')    queryParams.codigo = this.filtros.codigo;
            if(this.filtros.disponible != 'TODO')    queryParams.disponible = this.filtros.disponible;
            if(this.filtros.relevado != 'TODO')    queryParams.relevado = this.filtros.relevado;
            if(this.filtros.pendiente != 'TODO')    queryParams.pendiente = this.filtros.pendiente;
            if(this.filtros.not_wanted != 'TODO')    queryParams.not_wanted = this.filtros.not_wanted;
            if(this.filtros.has_articulo_selected != 'TODO')    queryParams.has_articulo_selected = this.filtros.has_articulo_selected;
            if(this.filtros.electricos != 'TODO')    queryParams.electricos = this.filtros.electricos;
            if(this.filtros.desc_es != 'TODO')    queryParams.desc_es = this.filtros.desc_es;
            if(this.filtros.publicar_ml != 'TODO')    queryParams.publicar_ml = this.filtros.publicar_ml;
            if(this.filtros.publicar_wc != 'TODO')    queryParams.publicar_wc = this.filtros.publicar_wc;

            let query = '';
            for( var key in queryParams ) {
                var value = queryParams[key];
                if(query == '') query+= '?'+key+'='+value;
                else query+= '&'+key+'='+value;
            }
            this.$router.push({ query: Object.assign({}, {}, queryParams) }).catch(() => {});
            return query;
        },
        async openModalImagenes(id_producto){
            try {
                let response = await this.$http.get(`producto/${id_producto}/img`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener imagenes del producto');

                this.$refs.modalGaleriaImages.showModal(response.body.data);
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        articulosRelacionados(id_producto){
            this.$router.push({ name: 'articulosProductos' , params: {id:id_producto}});
        }
    },
    async beforeMount(){

        this.pagination.page = this.$route.query.page || 1;
        this.pagination.limit = this.$route.query.limit || 10;
        this.filtros.keyword = this.$route.query.keyword || '';
        this.filtros.marca = this.$route.query.marca || '';
        this.filtros.sku = this.$route.query.sku || '';
        this.filtros.medidas = this.$route.query.medidas || 'TODO';
        this.filtros.pesos = this.$route.query.pesos || 'TODO';
        this.filtros.img = this.$route.query.img || 'TODO';
        this.filtros.video = this.$route.query.video || 'TODO';
        this.filtros.manual = this.$route.query.manual || 'TODO';
        this.filtros.attr = this.$route.query.attr || 'TODO';
        this.filtros.codigo = this.$route.query.codigo || 'TODO';
        this.filtros.disponible = this.$route.query.disponible || 'TODO';
        this.filtros.relevado = this.$route.query.relevado || 'TODO';
        this.filtros.pendiente = this.$route.query.pendiente || 'TODO';
        this.filtros.not_wanted = this.$route.query.not_wanted || 'TODO';
        this.filtros.has_articulo_selected = this.$route.query.has_articulo_selected || 'TODO';
        this.filtros.electricos = this.$route.query.electricos || 'TODO';
        this.filtros.desc_es = this.$route.query.desc_es || 'TODO';
        this.filtros.publicar_ml = this.$route.query.publicar_ml || 'TODO';
        this.filtros.publicar_wc = this.$route.query.publicar_wc || 'TODO';

        this.loading = true;
        await this.getListaProductos();
        this.getListaMarcas();
        this.loading = false;
    }
}
</script>

<style scoped>
    .item-row{
        border: 1px solid rgb(0 0 0 / 25%);
        padding: 10px 15px;
        border-radius: 5px;
    }

    .datos-item{
        font-size: small;
    }
</style>