<template>
    <div>
        <div v-if="loading" class="d-flex justify-content-center mt-5">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <div v-else>
            <b-card header-tag="header" >
                <template #header>
                    <div>
                        <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                            <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                        </b-button>
                        <div class="float-left table-panel-title mr-5">
                            Articulos <span v-if="!loading">[ {{pagination.count}} ]</span>
                        </div>
                        <div class="float-right">
                            <b-button-group size="sm">
                                <b-button variant="secondary" @click="visibleFiltros = !visibleFiltros">Filtros</b-button>
                                <b-button variant="primary" @click="agregar">Agregar</b-button>
                            </b-button-group>
                        </div>
                    </div>  
                    <div class="clearfix"></div>
                    <!-- Filtros -->
                    <b-collapse id="collapse-4" v-model="visibleFiltros" class="mt-4">
                        <div class="row">
                            <!-- Proveeodor -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="Proveeodor">
                                    <b-form-select
                                        type="text" 
                                        name="filtros-id_proveedor" 
                                        v-model.trim="filtros.id_proveedor"
                                        :options="proveedores"
                                        class="form-control"  
                                        :disabled = "loading.general"
                                    >
                                        <template #first>
                                            <b-form-select-option value="">Todos</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- sku_proveedor -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="SKU">
                                    <b-form-input
                                        type="text" 
                                        name="filtros-sku_proveedor" 
                                        v-model.trim="filtros.sku_proveedor"
                                        class="form-control" 
                                        :disabled = "loading.general"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <!-- Keyword -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="Keyword">
                                    <b-form-input
                                        type="text" 
                                        name="filtros-keyword" 
                                        v-model.trim="filtros.keyword"
                                        class="form-control" 
                                        :disabled = "loading.general"
                                    ></b-form-input>
                                </b-input-group>
                            </div>  
                            <!-- Disponible -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="Disponible">
                                    <b-form-select
                                        type="text" 
                                        name="filtros-disponible" 
                                        v-model.trim="filtros.disponible"
                                        :options="booleanOps"
                                        class="form-control"  
                                        :disabled = "loading.general"
                                    >
                                        <template #first>
                                            <b-form-select-option value="">Todos</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>  
                            <!-- Producto -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="Producto">
                                    <b-form-select
                                        type="text" 
                                        name="filtros-producto" 
                                        v-model.trim="filtros.producto"
                                        :options="booleanOps"
                                        class="form-control"  
                                        :disabled = "loading.general"
                                    >
                                        <template #first>
                                            <b-form-select-option value="">Todos</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div> 

                            <div class="col-12">
                                <b-button class="float-right mb-2" size="sm" @click="aplicarFiltros()" variant="primary">
                                    Aplicar
                                </b-button>
                            </div>       
                        </div>
                        
                    </b-collapse>             
                </template>
                
                <div v-if="loading" class="d-flex justify-content-center mt-5">
                    <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
                </div>
                <div v-else class="mb-3">
                    <ul class="list-group mb-3">
                        <li class="list-group-item" v-for="(articulo, index) in articulos" :key="'art-'+index">
                            <div class="row">
                                <div class="col-2 text-center">
                                    <b-link 
                                        :href="articulo.link_item_proveedor"
                                        target="_blank"
                                    >
                                        <img 
                                            :src="getUrlImg(articulo.mini_img)" 
                                            class="img-thumbnail pointer mb-3" 
                                            :alt="articulo.titulo" 
                                            style="max-width: 120px;"
                                            onerror="javascript:this.src='https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png'"
                                        >
                                    </b-link>
                                </div> 
                                <div class="row col-9">
                                    <div class="col-12 col-md-4 datos-item">
                                        <span class="mb-sm-2"><b>Titulo: </b>{{ articulo.titulo }}</span><br>
                                        <span class="mb-sm-2"><b>Marca: </b>{{ articulo.marca }}</span><br>
                                        <span class="mb-sm-2"><b>Modelo: </b>{{ articulo.modelo }}</span><br>
                                        <span class="mb-sm-2"><b>Proveedor: </b>{{ getNameProveedor(articulo.id_proveedor) }}</span><br>
                                        <span class="mb-sm-2"><b>SKU Proveedor: </b>{{ articulo.sku_proveedor }}</span><br>
                                    </div>
                                    <div class="col-12 col-md-5 datos-item">
                                        <span class="mb-sm-2"><b>Disponible: </b>{{ articulo.disponible ? 'Si' : 'No'}}</span><br>
                                        <span class="mb-sm-2"><b>Stock Prov.: </b>{{ articulo.stock_dispo }}</span><br>
                                        <span class="mb-sm-2"><b>Dispo Stock Prov.: </b>{{ articulo.stock_proveedor }}</span><br>
                                        <span class="mb-sm-2"><b>Dispo Stock Str.: </b>{{ articulo.stock_proveedor_str }}</span><br>
                                        <span class="mb-sm-2"><b>Precio: </b>{{ articulo.precio_lista }} {{ articulo.precio_lista_moneda }}</span><br>
                                    </div>
                                    <div class="col-12 col-md-3 datos-item">
                                        <span class="mb-sm-2"><b>Imagenes: </b>{{ articulo.imgs.length }}</span><br>
                                        <span class="mb-sm-2"><b>Videos: </b>{{ articulo.videos.length }}</span><br>
                                        <span class="mb-sm-2"><b>Manuales: </b>{{ articulo.manuales.length }}</span><br>
                                        <span class="mb-sm-2"><b>Id Producto: </b>{{ articulo.id_producto }}</span><br>
                                    </div>
                                </div>
                                <div class="col-1">
                                <template>
                                    <b-dropdown variant="outline-ligth" class="float-right card-dropdown">
                                        <b-dropdown-item variant="warning" @click="editArticulo(articulo)">
                                            <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                                            Editar
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </div>
                            </div>
                        </li>
                    </ul>
                   
                    <b-pagination
                        v-model="pagination.page"
                        :total-rows="pagination.count"
                        :per-page="pagination.limit"
                        align="center"
                        first-number
                        last-number
                        @change="handlePageChange"
                    ></b-pagination>

                </div>

            </b-card>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            visibleFiltros: false,
            articulos: [],
            proveedores: [],
            filtros: {
                id_proveedor: '',
                sku_proveedor: '',
                keyword: '',
                disponible: '',
                producto: ''
            },
            pagination:{
                page: 1,
                limit: 10,
                count: 0,
            },
            booleanOps: [{value: 'no', text: "No"},{value: 'si', text: "Si"}],
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getArticulos(){
            try {
                let query = `articulo`+this.armarQuery(false);

                let response = await this.$http.get( query , { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error(response.body.err.message);
                console.log('Articulos',response.body.data);

                this.articulos = response.body.data.rows;
                this.pagination.page = response.body.data.page;
                this.pagination.limit = response.body.data.limit;
                this.pagination.count = response.body.data.count;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getProveedores(){
            try {
                let response = await this.$http.get('negocio/proveedor', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener proveedores');

                this.proveedores = [];
                response.body.data.forEach(element => {
                    this.proveedores.push({
                        value: element.id,
                        text: element.nombre
                    })
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async aplicarFiltros(){
            this.loading = true;
            this.pagination.page = 1;
            await this.getArticulos();
            this.loading = false;
        },
        getUrlImg(url){
            if(url) return url;
            return 'https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png';
        },
        armarQuery(){
            let queryParams = {};
            queryParams.page = this.pagination.page;
            queryParams.limit = this.pagination.limit;
            if(this.filtros.id_proveedor != '')    queryParams.id_proveedor = this.filtros.id_proveedor;
            if(this.filtros.keyword != '')    queryParams.keyword = this.filtros.keyword;
            if(this.filtros.sku_proveedor != '')    queryParams.sku_proveedor = this.filtros.sku_proveedor;
            if(this.filtros.disponible != '')    queryParams.disponible = this.filtros.disponible;
            if(this.filtros.producto != '')    queryParams.producto = this.filtros.producto;

            let query = '';
            for( var key in queryParams ) {
                var value = queryParams[key];
                if(query == '') query+= '?'+key+'='+value;
                else query+= '&'+key+'='+value;
            }
            this.$router.push({ query: Object.assign({}, {}, queryParams) }).catch(() => {});
            return query;
        },
        agregar(){
            this.$router.push({ name: 'addArticulo' });
        },
        editArticulo(articulo){
            console.log('editArticulo',articulo);
            this.$swal.fire({
                title: 'Editar articulo',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: `Cancelar`,
                confirmButtonText: `Guardar`,
                reverseButtons: true,
                html:
                    `
                    <input type="text" id="id_proveedor_edit" value="${articulo.id_proveedor}" style="display:none">
                    <input type="text" id="sku_proveedor_edit" value="${articulo.sku_proveedor}" style="display:none">
                    <div class="input-group mb-3">
                        <span class="input-group-text">Disponible</span>
                        <select class="form-control" id="disponible_edit">
                            <option value="false" ${!articulo.disponible ? 'selected' : ''}>No</option>
                            <option value="true" ${articulo.disponible ? 'selected' : ''}>Si</option>
                        </select>
                    </div>
                    <div class="input-group mb-3 mt-5">
                        <span class="input-group-text">Precio</span>
                        <input type="number" class="form-control" id="precio_lista_edit" value="${articulo.precio_lista}">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">Moneda</span>
                        <select class="form-control" id="precio_lista_moneda_edit">
                            <option value="DOLAR" ${!articulo.precio_lista_moneda == 'DOLAR' ? 'selected' : ''}>DOLAR</option>
                            <option value="EURO" ${!articulo.precio_lista_moneda == 'EURO' ? 'selected' : ''}>EURO</option>
                            <option value="ARG" ${!articulo.precio_lista_moneda == 'ARG' ? 'selected' : ''}>ARG</option>
                        </select>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">Stock propio</span>
                        <input type="number" class="form-control" id="stock_propio_edit" value="${articulo.stock_propio}">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">Stock prov.</span>
                        <input type="number" class="form-control" id="stock_proveedor_edit" value="${articulo.stock_proveedor}">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">Dispo stock.</span>
                        <input type="number" class="form-control" id="stock_dispo_edit" value="${articulo.stock_dispo}">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">Dispo stock str.</span>
                        <input type="text" class="form-control" id="stock_proveedor_str_edit" value="${articulo.stock_proveedor_str}">
                    </div>
                    `
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    let datos = {
                        id_proveedor: document.getElementById('id_proveedor_edit').value.trim(),
                        sku_proveedor: document.getElementById('sku_proveedor_edit').value.trim(),
                        disponible: document.getElementById('disponible_edit').value.trim(),
                        precio_lista: document.getElementById('precio_lista_edit').value.trim(),
                        precio_lista_moneda: document.getElementById('precio_lista_moneda_edit').value.trim(),
                        stock_propio: document.getElementById('stock_propio_edit').value.trim(),
                        stock_proveedor: document.getElementById('stock_proveedor_edit').value.trim(),
                        stock_dispo: document.getElementById('stock_dispo_edit').value.trim(),
                        stock_proveedor_str: document.getElementById('stock_proveedor_str_edit').value.trim()
                    }

                    await this.sendEditArticulo(datos);
                }
            })
        },
        async sendEditArticulo(datos) {
            try {
                let response = await this.$http.put('articulo/manual', datos , { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken }});
                if(!response.body.success) throw new Error(response.body.err.message);
                console.log('onEdit response',response.body);

                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Articulo editado',
                    showConfirmButton: false,
                    timer: 1500
                });
                
                await this.getArticulos();

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async handlePageChange(value){
            this.loading = true;
            this.pagination.page = value;
            await this.getArticulos();
            this.loading = false;
        },
        getNameProveedor(id_proveedor){
            let proveedor = this.proveedores.find( element => id_proveedor == element.value);
            return proveedor.text;
        }
    },
    async beforeMount(){
        this.loading = true;
        await this.getProveedores();
        await this.getArticulos();
        this.loading = false;
    }
}
</script>

<style scoped>

</style>