<template>
    <div class="container pt-4">
        <div v-if="loading" class="d-flex justify-content-center mt-5">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <div v-else>
            <b-card header-tag="header" >
                <template #header>
                    <div>
                        <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                            <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                        </b-button>
                        <div class="float-left table-panel-title mr-5">Cuentas</div>
                        <div class="float-right">
                            <b-button-group size="sm">
                                <b-button variant="secondary" @click="visibleFiltros = !visibleFiltros">Filtros</b-button>
                                <b-button variant="primary" @click="askAgregarCuenta">Agregar</b-button>
                            </b-button-group>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <b-collapse id="collapse-4" v-model="visibleFiltros" class="mt-4">
                        <div class="row">
                            <div class="col-sm-12 col-lg-6 mb-3">
                                <b-input-group prepend="Keyword">
                                    <b-form-input
                                        type="text" 
                                        name="filtros-keyword" 
                                        v-model.trim="filtros.keyword"
                                        class="form-control"  
                                        :disabled = "loading.general"
                                    >
                                    </b-form-input>
                                </b-input-group>
                            </div>
                            <div class="col-sm-12 col-lg-6 mb-3">
                                <b-input-group prepend="Pais">
                                    <b-form-select
                                        type="text" 
                                        name="filtros-pais" 
                                        v-model.trim="filtros.pais"
                                        :options="paises"
                                        class="form-control"  
                                        :disabled = "loading.general"
                                    >
                                        <template #first>
                                            <b-form-select-option value="">Todos</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>
                        </div>
                    </b-collapse>
                    
                </template>
                
                <ul class="list-group">
                    <li class="list-group-item" v-for="cuenta in arrCuentasFiltrados" :key="cuenta.id">
                        <div class="row">
                            <div class="col-10 row"> 
                                <div class="col-12 col-lg-8">
                                    <div class="datos-imp">
                                        <a :href="cuenta.permalink" target="_blank" >
                                            {{cuenta.site_id}} - {{cuenta.id_cuenta}} - {{cuenta.nickname}}
                                        </a>
                                    </div>
                                    <div class="datos-sec">{{cuenta.nombre}}</div>
                                    <div class="datos-sec">{{cuenta.email}}</div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="mb-2">
                                        <span v-bind:class="['badge mr-2',checkAccesToken(cuenta)?'bg-success':'bg-danger']">
                                            Token {{checkAccesToken(cuenta)?'Activo':'Vencido'}}
                                        </span>
                                        <span v-bind:class="['badge mr-2',cuenta.refresh_token?'bg-success':'bg-danger']">
                                            Ref. Token
                                        </span>
                                        <span v-bind:class="['badge mr-2',(cuenta.site_status == 'active')?'bg-success':'bg-danger']">
                                            {{cuenta.site_status}}
                                        </span>
                                    </div>

                                    <div>
                                        <span v-bind:class="['badge mr-2',cuenta.status?'bg-success':'bg-danger']">
                                            {{cuenta.status?'Activa':'No Activa'}}
                                        </span>
                                        <span v-bind:class="['badge mr-2',cuenta.active_notif?'bg-success':'bg-danger']">
                                            {{cuenta.active_notif?'Notif. Activadas':'Notif. No Activadas'}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <template>
                                    <b-dropdown variant="outline-ligth" class="float-right card-dropdown">
                                        <b-dropdown-item variant="warning" @click="askEditCuenta(cuenta)" >
                                            <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                                            Editar
                                        </b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item variant="success" @click="askRefreshToken(cuenta)">
                                            <b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
                                            Refresh Token
                                        </b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item variant="danger" @click="askDelCuenta(cuenta)">
                                            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                            Borrar
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </div>
                        </div>
                    </li>
                </ul>
            </b-card>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            visibleFiltros: false,
            filtros: {
                keyword: '',
                pais: ''
            },
            cuentas: [],
            paises: [
                {value: 'MLA', text: "Argentina"}
            ],
        }
    },
    computed: {
        arrCuentasFiltrados() {
            return this.cuentas.filter(element => {
                let passPais = true;
                let passKey = true;
                if(this.filtros.pais != "" && element.site_id != this.filtros.pais) passPais = false;
                if(this.filtros.keyword != ""){
                    passKey = false;
                    let key = this.filtros.keyword.toLowerCase();
                    let nombre = element.nombre.toLowerCase();
                    let nickname = element.nickname.toLowerCase();
                    let email = element.email.toLowerCase();
                    if(nombre.includes(key)) passKey = true;
                    if(nickname.includes(key)) passKey = true;
                    if(email.includes(key)) passKey = true;
                }
                if(passPais && passKey) return element;
            });
        },
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        askAgregarCuenta(){
            this.$swal.fire({
                title: 'Agregar Cuenta ML',
                icon: 'info',
                html: `<p>
                        Pasos para linkear una cuenta:<br>
                        1 - Abrir una pestaña de Mercado Libre (<a href="https://www.mercadolibre.com.ar/" target="_blank">link</a>)<br>
                        2 - Loguear con la cuenta que desea linkear<br>
                        3 - Apretar el boton Linkear<br>
                        4 - Aceptar los terminos de Mercado Libre
                    </p>`,
                showCancelButton: true,
                confirmButtonText: `Agregar`,
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    // this.$swal.fire('Saved!', '', 'success')
                    this.getAuthLinkMeli();
                }
            })
        },
        async getAuthLinkMeli(){
            try {
                let response = await this.$http.get('meli/authlink', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                console.log("Rta Auth Link: ",response);
                if(!response.body.success) throw new Error('Al obtener auth link');

                window.location.href = response.body.data;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getCuentas(){
            try {
                let response = await this.$http.get('meli/cuenta', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                console.log("Rta Cuentas: ",response);
                if(!response.body.success) throw new Error('Al obtener lista de cuentas');

                this.cuentas = response.body.data;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async checkMeliCode(){
            let code = this.$route.query.code || null;
            let error = this.$route.query.error || null;
            let error_description = this.$route.query.error_description || null;
            //Si viene el codigo debo agregar la cuenta
            if(code){
                try {
                    let response = await this.$http.post('meli/cuenta', {code:code}, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } })
                    if(!response.body.success) throw new Error('Al agregar cuenta');

                    this.$router.push(this.$route.path);

                } catch (error) {
                    this.$router.push(this.$route.path);
                    let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                    this.showErrorModal(message)
                }
            } 

            if(error){
                console.log('Error Meli: ',error);
                console.log('Error Desc Meli: ',error_description);
                this.showErrorModal(error_description);
            }
        },
        checkAccesToken(cuenta){
            if(cuenta.access_token && (Math.floor(Date.now() / 1000) < parseInt(cuenta.expires_in))) return true;
            else return false;
        },
        askEditCuenta(cuenta){
            this.$swal.fire({
                title: 'Multiple inputs',
                html:
                    `
                    <div class="input-group mb-3 mt-5">
                        <span class="input-group-text" id="basic-addon3">Activo</span>
                        <select class="form-control" id="swal-active">
                            <option value="true">Activo</option>
                            <option value="false">No Activo</option>
                        </select>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon3">Activo Notif.</span>
                        <select class="form-control" id="swal-active-notif">
                            <option value="true">Activo</option>
                            <option value="false">No Activo</option>
                        </select>
                    </div>
                    `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: `Guardar`,
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let formValues = {
                        status: document.getElementById('swal-active').value,
                        active_notif: document.getElementById('swal-active-notif').value
                    }  

                    this.$http.put('meli/cuenta/'+cuenta.id, formValues, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } })
                        .then(response => {
                            if (response.body && response.body.success) {
                                this.getCuentas();
                                this.$swal.fire({
                                icon: 'success',
                                title: 'Cuenta editada',
                                timer: 2500
                            })
                            } else {
                                console.log(response.body);
                                throw new Error('Al editar cuenta');
                            }
                        }, response => {
                            let error = 'Al conectar con el servidor';
                            if (response.body && !response.body.success) {
                                error = response.body.err.message || 'Al conectar con el servidor';
                            }
                            this.showErrorModal(error)
                        });
                }
            })

            document.getElementById('swal-active').value=cuenta.status;
            document.getElementById('swal-active-notif').value=cuenta.active_notif;

        },
        async askRefreshToken(cuenta){
            try {
                let response = await this.$http.put('meli/cuenta/'+cuenta.id+'/refreshtoken', {}, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                console.log("Rta Auth Link: ",response);
                if(!response.body.success) throw new Error('Al refrescar token');

                this.getCuentas();
                this.$swal.fire({
                    icon: 'success',
                    title: 'Token Actualizado',
                    timer: 2500
                })

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        askDelCuenta(cuenta){
            console.log('borrar',cuenta);
            this.$swal.fire({
                title: 'Esta seguro que desea borrar la cuenta?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Borrar',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$http.delete('meli/cuenta/'+cuenta.id, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } })
                        .then(response => {
                            if (response.body && response.body.success) {
                                console.log(response.body);
                                this.getCuentas();
                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'Cuenta Borrada',
                                    timer: 2500
                                })
                            } else {
                                console.log(response.body);
                                throw new Error('Al borrar cuenta');
                            }
                        }, response => {
                            let error = 'Al conectar con el servidor';
                            if (response.body && !response.body.success) {
                                error = response.body.err.message || 'Al conectar con el servidor';
                            }
                            this.showErrorModal(error)
                        });
                }
            })
        }
    },
    async beforeMount(){
        this.loading = true;
        await this.checkMeliCode();
        this.getCuentas();
        this.loading = false;
    }
}
</script>

<style scoped>
    .datos-imp{
        font-family: 'Russo One', sans-serif;
        font-size: 1.3em;
    }

    .datos-sec{
        font-size: 0.8em;
    }
</style>