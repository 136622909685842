<template>
  <div class="container m-auto pt-4">
      <div class="text-center">
        <img :src="ImgEmpresa" alt="Imagen Empresa" >
      </div>
      
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data(){
    return {
      ImgEmpresa: require('../../assets/img/therockstore.png'),
    }
  },
  methods:{
    showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
  },
  computed:{
    ...mapGetters([
      'getUsuario',
      'getToken',
      'getPermisos',
    ])
  }
}
</script>

<style>

</style>