<template>
    <div class="container pt-4">
        <div v-if="loading" class="d-flex justify-content-center mt-5">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <div v-else>
            <b-card header-tag="header" >
                <template #header>
                    <div>
                        <div class="float-left table-panel-title mr-5">Usuarios</div>
                        <div class="float-right">
                            <b-button-group size="sm">
                                <b-button variant="secondary" @click="visibleFiltros = !visibleFiltros">Filtros</b-button>
                                <b-button variant="primary" @click="agregar">Agregar</b-button>
                            </b-button-group>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <!-- Filtros -->
                    <b-collapse id="collapse-4" v-model="visibleFiltros" class="mt-4">
                        <div class="row">
                            <!-- Keyword -->
                            <div class="col-sm-12 col-lg-6 mb-3">
                                <b-input-group prepend="Keyword">
                                    <b-form-input
                                        type="text" 
                                        name="filtros-keyword" 
                                        v-model.trim="filtros.keyword"
                                        class="form-control" 
                                        :disabled = "loading.general"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <!-- Pais -->
                            <div class="col-sm-12 col-lg-6 mb-3">
                                <b-input-group prepend="Pais">
                                    <b-form-select
                                        type="text" 
                                        name="filtros-pais" 
                                        v-model.trim="filtros.pais"
                                        :options="paises"
                                        class="form-control"  
                                        :disabled = "loading.general"
                                    >
                                        <template #first>
                                            <b-form-select-option value="">Todos</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>
                        </div>
                        
                    </b-collapse>
                    
                </template>
                
                

                <ul class="list-group">
                    <li class="list-group-item" v-for="usuario in arrUsuariosFiltrados" :key="usuario.id">
                        <div class="row">
                            <div class="col-10">   
                                <div class="float-left mr-3 pointer">
                                    <img :src="usuario.img" class="img-thumbnail" alt="Imagen usuario">
                                </div>
                                <div class="">
                                    <span class="title-usuario">{{usuario.apellido}} , {{usuario.nombre}} </span><br>
                                    <div class="row">
                                        <div class="col-12 col-md-7"> 
                                            <div class="datos-usuario">
                                                <span>Usuario: {{usuario.usuario}}</span><br>
                                                <span>Mail: {{usuario.mail}}</span><br>
                                                <span>Fecha Nacimiento: {{usuario.fecha_nacimiento}}</span><br>
                                                <span>Fecha Ingreso: {{usuario.fecha_ingreso}}</span><br>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-5"> 
                                            <div class="datos-usuario">
                                                <span>Pais: {{usuario.pais}}</span><br>
                                                <span>Direccion: {{usuario.direccion}}</span><br>
                                                <span>Tell: {{usuario.tell}}</span><br>
                                                <span v-if="usuario.tipo_doc">{{usuario.tipo_doc}}: {{usuario.documento}}</span>
                                                <br v-if="usuario.tipo_doc">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <template>
                                    <b-dropdown variant="outline-ligth" class="float-right card-dropdown">
                                        <b-dropdown-item variant="info" @click="permisos(usuario.id)">
                                            <b-icon icon="box-arrow-in-down" aria-hidden="true"></b-icon>
                                            Permisos
                                        </b-dropdown-item>
                                        <b-dropdown-divider v-if="usuario.borrable"></b-dropdown-divider>
                                        <b-dropdown-item variant="warning" v-if="usuario.borrable" @click="editar(usuario.id)">
                                            <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                                            Editar
                                        </b-dropdown-item>
                                        <b-dropdown-divider v-if="usuario.borrable"></b-dropdown-divider>
                                        <b-dropdown-item variant="danger" v-if="usuario.borrable" @click="checkBorrar(usuario.id)">
                                            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                            Borrar
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </div>
                        </div>
                    </li>
                </ul>

            </b-card>

            


        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            visibleFiltros: false,
            filtros: {
                keyword: '',
                pais: ''
            },
            usuarios: [],
            paises: [],
        }
    },
    computed: {
        arrUsuariosFiltrados() {
            return this.usuarios.filter(element => {
                let passPais = true;
                let passKey = true;
                if(this.filtros.pais != "" && element.pais != this.filtros.pais) passPais = false;
                if(this.filtros.keyword != ""){
                    passKey = false;
                    let key = this.filtros.keyword.toLowerCase();
                    let usuario = element.usuario.toLowerCase();
                    let nombre = element.nombre.toLowerCase();
                    let apellido = element.apellido.toLowerCase();
                    let mail = element.mail.toLowerCase();
                    if(usuario.includes(key)) passKey = true;
                    else if(nombre.includes(key)) passKey = true;
                    else if(apellido.includes(key)) passKey = true;
                    else if(mail.includes(key)) passKey = true;
                }
                if(passPais && passKey) return element;
            });
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        getUsuarios(){
            this.loading = true;
            this.$http.get('user-sis/usuario', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } })
                .then(response => {
                    if (response.body && response.body.success) {
                        this.usuarios = response.body.data;
                        this.paises =[];
                        this.usuarios.forEach(element => {
                            if(this.paises.indexOf(element.pais) == -1) this.paises.push(element.pais);
                        });
                        this.loading = false;
                    } else {
                        throw new Error('Al obtener usuarios');
                    }
                }, response => {
                    let error = 'Al conectar con el servidor';
                    if (response.body && !response.body.success) {
                        error = response.body.err.message || 'Al conectar con el servidor';
                    }
                    this.showErrorModal(error)
                });
        },
        checkBorrar(id){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro de borrar el usuario?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Borrar',
                cancelButtonText: 'No',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.borrar(id);
                    }
                })
        },
        borrar(id){
            this.$http.delete(`user-sis/usuario/${id}`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } })
                .then(response => {
                    if (response.body && response.body.success) {
                        this.getUsuarios();
                        this.$swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'El usuario fue borrado',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        throw new Error('Al borrar usuario.');
                    }
                }, response => {
                    let error = 'Al conectar con el servidor';
                    if (response.body && !response.body.success) {
                        error = response.body.err.message || 'Al conectar con el servidor';
                    }
                    this.showErrorModal(error);
                });
        },
        agregar(){
            this.$router.push({ name: 'addUsuarios' });
        },
        editar(id){
            this.$router.push({ name: 'editUsuarios' , params: {id:id}});
        },
        permisos(id){
            this.$router.push({ name: 'permisosUsuarios' , params: {id:id}});
        }
    },
    beforeMount(){
        let error = this.$route.params.error || this.$route.query.error || false;
        if(error) this.showErrorModal(error);
        this.getUsuarios();
    }
}
</script>

<style scoped>
    .img-thumbnail {
        max-width: 100px;
        max-height: 100px;
    }

    .title-usuario{
        font-family: 'Russo One', sans-serif;
        font-size: 1.3em;
    }

    .datos-usuario{
        font-size: 0.8em;
    }
</style>