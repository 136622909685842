<template>
  <b-modal ref="modalImgs" size="lg" centered title="Imagenes Producto">
    <div>
      <b-carousel
        id="modalImgs-carousel-1"
        v-model="slide"
        no-animation
        controls
        indicators
        background="#ababab"
        style="text-shadow: 1px 1px 2px #333; padding-top:20px"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide v-for="(image, index) in arrImgs" :key="index">
          <template #img>
            <img
              class="d-block img-fluid m-auto"
              :src="getUrlImg(image)" 
              style="max-width: 500px; height: 500px"
              alt="image slot"
              onerror="javascript:this.src='https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png'"
            >
          </template>
        </b-carousel-slide>

    </div>
    <template #modal-footer>
        <div class="w-100 p-2">
          <b-button
            variant="danger"
            size="sm"
            @click="hideModal()"
            class="w-100"
          >
            Cancelar
          </b-button>
        </div>
      </template>
  </b-modal>
</template>

<script>
    export default {
        data(){
            return {
              arrImgs: [],
              slide: 0,
              sliding: null,
              show: false
            }
        },
        methods: {
            showModal(arrImgs) {
              console.log('arrImgs',arrImgs);
              this.arrImgs = [];
              arrImgs.forEach(element => {
                this.arrImgs.push(element.path);
              });
              this.$refs.modalImgs.show();
            },
            onSlideStart() {
              this.sliding = true
            },
            onSlideEnd() {
              this.sliding = false
            },
            hideModal() {
              this.$refs.modalImgs.hide()
            },
            getUrlImg(url){
                if(url) return url;
                return 'https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png';
            },
        }
    }
</script>

<style>
  .carousel-indicators {
      position: static !important;
      display: flex;
      justify-content: center;
      padding-left: 0;
      margin-top: 30px;
      margin-right: 15%;
      margin-left: 15%;
      list-style: none;
  }
</style>