<template>
<div class="container pt-4" style="max-width: 85%">
        <b-card header-tag="header" >
            <template #header>
                <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">Permisos Usuario</div>
                <div class="float-right">
                    <b-button-group size="sm">
                        <b-button variant="primary" @click="agregarPermiso">
                            <b-icon icon="plus"></b-icon>
                        </b-button>
                    </b-button-group>
                </div>
            </template>
            
            <ul class="list-group" v-if="!loading">
                <li class="list-group-item" v-for="(permiso,index) in permisos" :key="index">
                    <div class="row">
                        <div class="col-12 col-lg-3">
                            <b-input-group prepend="Sistema">
                                <b-form-select v-model="permiso.sistema" :options="optionSistemas">
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-lg-3">
                            <b-input-group prepend="Permiso">
                                <b-form-select v-model="permiso.permiso" :options="optionPermiso(permiso.sistema)">
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-lg-4">
                            <b-input-group prepend="V" class="float-left mr-1 w-auto">
                                <b-input-group-append is-text>
                                    <b-form-checkbox 
                                        switch 
                                        class="mr-n2"
                                        v-model="permiso.V"
                                        :checked="permiso.V"
                                    >
                                        <span class="sr-only">V</span>
                                    </b-form-checkbox>
                                </b-input-group-append>
                            </b-input-group>

                            <b-input-group prepend="A" class="float-left mr-1 w-auto">
                                <b-input-group-append is-text>
                                    <b-form-checkbox 
                                        switch 
                                        class="mr-n2"
                                        v-model="permiso.A"
                                        :checked="permiso.A"
                                    >
                                        <span class="sr-only">A</span>
                                    </b-form-checkbox>
                                </b-input-group-append>
                            </b-input-group>

                            <b-input-group prepend="B" class="float-left mr-1 w-auto">
                                <b-input-group-append is-text>
                                    <b-form-checkbox 
                                        switch 
                                        class="mr-n2"
                                        v-model="permiso.B"
                                        :checked="permiso.B"
                                    >
                                        <span class="sr-only">B</span>
                                    </b-form-checkbox>
                                </b-input-group-append>
                            </b-input-group>

                            <b-input-group prepend="M" class="float-left mr-1 w-auto">
                                <b-input-group-append is-text>
                                    <b-form-checkbox 
                                        switch 
                                        class="mr-n2"
                                        v-model="permiso.M"
                                        :checked="permiso.M"
                                    >
                                        <span class="sr-only">M</span>
                                    </b-form-checkbox>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-12 col-lg-2 text-right">
                            <b-button text="Button" variant="danger"  size="sm" @click="borrarPermiso(index)">
                                <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                        </div>
                    </div>
                </li>
            </ul>


            <b-button v-if="!loading && permisos.length" variant="success" class="float-right mt-4" @click="guardarPermisos()">
                Guardar
            </b-button>
        </b-card>
    </div>  
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            idUsuario: null,
            sistemas: null,
            permisos: null,
        }
    },
    computed: {
        optionSistemas: function(){
            let arrSistemas = [{ value: null, text: 'Please select an option' , disabled: true }];
            for (let key in this.sistemas) {
                const element = this.sistemas[key];
                arrSistemas.push({value: element.nombre, text: element.nombre});
            }
            return arrSistemas;
        },
        optionPermiso: function(){
            return (sistema) => {
                let arrPermisos = [{ value: null, text: 'Please select an option' , disabled: true }];
                if(sistema){   
                    for (let key in this.sistemas[sistema].permisos) {
                        arrPermisos.push({value: key, text: key});
                    }
                }
                return arrPermisos;
                
            }
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getPermisosUsuario(){
            try {
                let response = await this.$http.get(`user-sis/usuario/${this.idUsuario}/permiso`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener permisos');
                
                this.permisos = response.body.data;
                    
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getSistemas(){
            try {
                let response = await this.$http.get(`user-sis/sistema`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                console.log(response);
                if (!response.body.success) throw new Error('Al obtener sistemas');
                this.sistemas = response.body.data; 

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        agregarPermiso(){
            this.permisos.push({
                sistema: null,
                permiso: null,
                V: false,
                A: false,
                B: false,
                M: false
            })
        },
        borrarPermiso(index){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro de borrar el permiso?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Borrar',
                cancelButtonText: 'No',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.permisos.splice(index, 1);
                    }
                })
        },

        async guardarPermisos(){
            try {
                console.log('permisos', this.permisos);
                let response = await this.$http.put(`user-sis/usuario/${this.idUsuario}/permiso/array`,{permisos:this.permisos}, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al editar permisos');
                this.getPermisosUsuario();
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Permisos Guardados',
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
            
        }
        
    },
    async beforeMount(){
        this.idUsuario = this.$route.params.id;
        this.loading = true;
        await this.getPermisosUsuario();
        await this.getSistemas();
        this.loading = false;
    },
}
</script>

<style scoped>
.img-thumbnail {
        max-width: 100px;
        max-height: 100px;
    }

    .title-empresa{
        font-family: 'Russo One', sans-serif;
        font-size: 1.3em;
    }
</style>