<template>
    <div class="container pt-4">
        <b-card header-tag="header" >
            <template #header>
                <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">Agregar Usuario</div>
            </template>
            
            <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">

                <div class="row">

                    <!-- Nombre -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Nombre">
                            <b-form-input
                                type="text" 
                                name="nombre" 
                                v-model.trim="formdata.nombre"
                                @blur="$v.formdata.nombre.$touch()"
                                :class="{error : $v.formdata.nombre.$error}"
                                class="form-control" 
                                autofocus 
                                :disabled = "loading.general"
                            ></b-form-input>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.nombre.$error">
                            <small v-if="!$v.formdata.nombre.required">Este campo es obligatorio</small>
                            <small v-else-if="!$v.formdata.nombre.minLength">Este campo debe tener al menos 4 caracteres</small>
                            <small v-else-if="$v.formdata.nombre.maxLength">Este campo debe tener maximo 100 caracteres</small>
                        </div>
                    </div>

                    <!-- Apellido -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Apellido">
                            <b-form-input
                                type="text" 
                                name="apellido" 
                                v-model.trim="formdata.apellido"
                                @blur="$v.formdata.apellido.$touch()"
                                :class="{error : $v.formdata.apellido.$error}"
                                class="form-control" 
                                :disabled = "loading.general"
                            ></b-form-input>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.apellido.$error">
                            <small v-if="!$v.formdata.apellido.required">Este campo es obligatorio</small>
                            <small v-else-if="!$v.formdata.apellido.minLength">Este campo debe tener al menos 4 caracteres</small>
                            <small v-else-if="$v.formdata.apellido.maxLength">Este campo debe tener maximo 100 caracteres</small>
                        </div>
                    </div>

                    <!-- Usuario -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Usuario">
                            <b-form-input
                                type="text" 
                                name="usuario" 
                                v-model.trim="formdata.usuario"
                                @blur="$v.formdata.usuario.$touch()"
                                :class="{error : $v.formdata.usuario.$error}"
                                class="form-control" 
                                :disabled = "loading.general"
                            ></b-form-input>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.usuario.$error">
                            <small v-if="!$v.formdata.usuario.required">Este campo es obligatorio</small>
                            <small v-else-if="!$v.formdata.usuario.minLength">Este campo debe tener al menos 4 caracteres</small>
                            <small v-else-if="$v.formdata.usuario.maxLength">Este campo debe tener maximo 100 caracteres</small>
                        </div>
                    </div>

                    <!-- Password -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="password">
                            <b-form-input
                                type="text" 
                                name="password" 
                                v-model.trim="formdata.password"
                                @blur="$v.formdata.password.$touch()"
                                :class="{error : $v.formdata.password.$error}"
                                class="form-control"  
                                :disabled = "loading.general"
                            ></b-form-input>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.password.$error">
                            <small v-if="!$v.formdata.password.required">Este campo es obligatorio</small>
                            <small v-else-if="!$v.formdata.password.minLength">Este campo debe tener al menos 4 caracteres</small>
                            <small v-else-if="$v.formdata.password.maxLength">Este campo debe tener maximo 255 caracteres</small>
                        </div>
                    </div>

                    <!-- Mail -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Mail">
                            <b-form-input
                                type="text" 
                                name="mail" 
                                v-model.trim="formdata.mail"
                                @blur="$v.formdata.mail.$touch()"
                                :class="{error : $v.formdata.mail.$error}"
                                class="form-control" 
                                :disabled = "loading.general"
                            ></b-form-input>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.mail.$error">
                            <small v-if="!$v.formdata.mail.required">Este campo es obligatorio</small>
                            <small v-else-if="!$v.formdata.mail.minLength">Este campo debe tener al menos 4 caracteres</small>
                            <small v-else-if="!$v.formdata.mail.maxLength">Este campo debe tener maximo 255 caracteres</small>
                            <small v-else-if="!$v.formdata.mail.email">Formato de mail incorrecto</small>
                        </div>
                    </div>


                    <!-- Tell -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Tell">
                            <b-form-input
                                type="text" 
                                name="tell" 
                                v-model.trim="formdata.tell"
                                @blur="$v.formdata.tell.$touch()"
                                :class="{error : $v.formdata.tell.$error}"
                                class="form-control" 
                                :disabled = "loading.general"
                            ></b-form-input>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.tell.$error">
                            <small v-if="!$v.formdata.tell.required">Este campo es obligatorio</small>
                            <small v-else-if="!$v.formdata.tell.minLength">Este campo debe tener al menos 4 caracteres</small>
                            <small v-else-if="$v.formdata.tell.maxLength">Este campo debe tener maximo 100 caracteres</small>
                        </div>
                    </div>

                    <!-- Pais -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Pais">
                            <b-form-select
                                type="text" 
                                name="pais" 
                                v-model.trim="formdata.pais"
                                @blur="$v.formdata.pais.$touch()"
                                :class="{error : $v.formdata.pais.$error}"
                                :options="paises"
                                class="form-control"  
                                :disabled = "loading.general"
                            >
                                <template #first>
                                    <b-form-select-option value="" disabled>Seleccionar un pais</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.pais.$error">
                            <small v-if="!$v.formdata.pais.required">Este campo es obligatorio</small>
                        </div>
                    </div>

                    <!-- Direccion -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Direccion">
                            <b-form-input
                                type="text" 
                                name="direccion" 
                                v-model.trim="formdata.direccion"
                                @blur="$v.formdata.direccion.$touch()"
                                :class="{error : $v.formdata.direccion.$error}"
                                class="form-control" 
                                :disabled = "loading.general"
                            ></b-form-input>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.direccion.$error">
                            <small v-if="!$v.formdata.direccion.required">Este campo es obligatorio</small>
                            <small v-else-if="!$v.formdata.direccion.minLength">Este campo debe tener al menos 4 caracteres</small>
                            <small v-else-if="$v.formdata.direccion.maxLength">Este campo debe tener maximo 255 caracteres</small>
                        </div>
                    </div>

                    <!-- Tipo Doc -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Tipo Doc">
                            <b-form-select
                                type="text" 
                                name="tipo_doc" 
                                v-model.trim="formdata.tipo_doc"
                                :options="tipos_doc"
                                class="form-control"  
                                :disabled = "loading.general"
                                :class="{error : $v.formdata.tipo_doc.$error}"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Seleccionar un tipo de documento</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.tipo_doc.$error">
                            <small v-if="!$v.formdata.tipo_doc.required">Este campo es obligatorio</small>
                        </div>
                    </div>

                    <!-- Documento -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Documento">
                            <b-form-input
                                type="text" 
                                name="documento" 
                                v-model.trim="formdata.documento"
                                class="form-control" 
                                :disabled = "loading.general"
                                :class="{error : $v.formdata.documento.$error}"
                            ></b-form-input>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.documento.$error">
                            <small v-if="!$v.formdata.documento.required">Este campo es obligatorio</small>
                            <small v-else-if="$v.formdata.documento.maxLength">Este campo debe tener maximo 100 caracteres</small>
                        </div>
                    </div>

                    <!-- Fecha Ingreso -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Fecha Ingreso">
                            <b-form-datepicker 
                                name="fecha_ingreso" 
                                v-model.trim="formdata.fecha_ingreso"
                                @blur="$v.formdata.fecha_ingreso.$touch()"
                                :class="{error : $v.formdata.fecha_ingreso.$error}"
                                class="form-control" 
                                :disabled = "loading.general"
                                placeholder=""
                                locale="en"
                            ></b-form-datepicker>

                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.fecha_ingreso.$error">
                            <small v-if="!$v.formdata.fecha_ingreso.required">Este campo es obligatorio</small>
                        </div>
                    </div>

                    <!-- Fecha Nacimiento -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Fecha Nacimiento">
                            <b-form-datepicker 
                                name="fecha_nacimiento" 
                                v-model.trim="formdata.fecha_nacimiento"
                                @blur="$v.formdata.fecha_nacimiento.$touch()"
                                :class="{error : $v.formdata.fecha_nacimiento.$error}"
                                class="form-control" 
                                :disabled = "loading.general"
                                placeholder=""
                                locale="en"
                            ></b-form-datepicker>

                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.fecha_nacimiento.$error">
                            <small v-if="!$v.formdata.fecha_nacimiento.required">Este campo es obligatorio</small>
                        </div>
                    </div>

                    <!-- Imagen -->
                    <div class="col-sm-12 mb-3">
                        <b-input-group prepend="Imagen">
                            <b-form-file
                                name="file_img" 
                                v-model="formdata.file_img"
                                placeholder="Seleccionar una imagen"
                                class="form-control" 
                                :disabled = "loading.general"
                                :class="{error : $v.formdata.file_img.$error}"
                                @change="checkFileImgExt"
                            ></b-form-file>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.file_img.$error || !validatefile.size || !validatefile.type">
                            <small v-if="!$v.formdata.file_img.required">Este campo es obligatorio</small>
                            <small v-if="!validatefile.size">El archivo debe ser menor a 20MB</small>
                            <small v-else-if="!validatefile.type">El archivo debe ser png o jpg</small>
                        </div>
                    </div>
                    
                </div>
                
                <div class="float-right mt-5">
                    <b-button type="reset" variant="danger" :disabled = "loading.general">Reset</b-button>
                    <b-button type="submit" variant="primary" class="ml-2" :disabled = "loading.general">Guardar</b-button>
                    
                </div>
                
            </b-form>

        </b-card>
    </div>  
    
</template>

<script>
const { required, minLength, maxLength, email } = require('vuelidate/lib/validators');

export default {
  data(){
        return {
            loading: {
                tipos_doc: false,
                general: false,
            },
            paises: this.$store.getters.getListaPaises,
            tipos_doc : [],
            file_ext: ['image/jpeg','image/png'],
            formdata: {
                nombre: '',
                apellido: '',
                usuario: '',
                mail: '',
                password: 'default',
                fecha_nacimiento: '',
                pais: 'Argentina',
                tipo_doc: '',
                documento: '',
                direccion: '',
                fecha_ingreso: '',
                tell: '',
                file_img: null
            },
            validatefile:{
                size: true,
                type: true
            }
        }
    },
    validations: {
        formdata:{
            nombre: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(100),
            },
            apellido: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(100),
            },
            usuario: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(100),
            },
            mail: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(255),
                email
            },
            password: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(255),
            },
            pais: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(150),
            },
            fecha_nacimiento: {
                required
            },
            tipo_doc: {
                required
            },
            documento: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(100),
            },
            direccion: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(255),
            },
            fecha_ingreso: {
                required
            },
            tell: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(100),
            },
            file_img: {
                required
            }

        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        checkLoading(){
            let loading = false;
            for (let key in this.loading){
                if(key !== 'general'){
                    if(this.loading[key]) loading = true;
                }
            }
            this.loading.general = loading;
        },
        onSubmit(){
            this.loading.general = false;
            this.$v.$touch();
            if(!this.$v.$invalid && this.validatefile.size && this.validatefile.type){
                console.log(this.formdata);
                let formData = new FormData();
                formData.append('nombre', this.formdata.nombre);
                formData.append('apellido', this.formdata.apellido);
                formData.append('usuario', this.formdata.usuario);
                formData.append('mail', this.formdata.mail);
                formData.append('password', this.formdata.password);
                formData.append('fecha_nacimiento', this.formdata.fecha_nacimiento);
                formData.append('pais', this.formdata.pais);
                formData.append('tipo_doc', this.formdata.tipo_doc);
                formData.append('documento', this.formdata.documento);
                formData.append('direccion', this.formdata.direccion);
                formData.append('fecha_ingreso', this.formdata.fecha_ingreso);
                formData.append('tell', this.formdata.tell);
                formData.append('file_img', this.formdata.file_img);

                this.$http.post('user-sis/usuario', formData , { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken , 'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        if (response.body && response.body.success) {
                            console.log(response.body);
                            this.onReset();
                            this.$swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: response.body.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            console.log(response.body);
                            throw new Error('Al crear usuario');
                        }
                    }, response => {
                        let error = 'Al conectar con el servidor';
                        if (response.body && !response.body.success) {
                            error = response.body.err.message || 'Al conectar con el servidor';
                        }
                        this.showErrorModal(error)
                    });
            }
            return false;
        },
        onReset(){
            this.tipos_doc  = [];
            this.formdata = {
                nombre: '',
                apellido: '',
                usuario: '',
                mail: '',
                password: '',
                fecha_nacimiento: '',
                pais: 'Argentina',
                tipo_doc: '',
                documento: '',
                direccion: '',
                fecha_ingreso: '',
                tell: '',
                img:'',
                file_img: null
            };
            this.error = {
                status: false,
                msg: ''
            };
            this.$nextTick(() => { this.$v.$reset() })
            this.traerListaTiposDoc();
        },
        traerListaTiposDoc(){
            this.loading.tipos_doc = true;
            this.checkLoading();
            this.$http.get('tipos-documento', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } })
                .then(response => {
                    if (response.body && response.body.success) {
                        this.tipos_doc = response.body.data;
                        
                        this.loading.tipos_doc = false;
                        this.checkLoading();
                    } else {
                        throw new Error('Al obtener tipos documento');
                    }
                }, response => {
                    let error = 'Al conectar con el servidor';
                    if (response.body && !response.body.success) {
                        error = response.body.err.message || 'Al conectar con el servidor';
                    }
                    this.showErrorModal(error)
                });
        },
        checkFileImgExt(e){
            const files =  e.target.files || e.dataTransfer.files;
            const file = files[0];
            this.validatefile.size = true;
            this.validatefile.type = true;
            if(file){
                if(file.size > 20971520){//20MB
                    this.validatefile.size = false;
                }
                if(!this.file_ext.includes(file.type)){
                    this.validatefile.type = false;
                }
            }
        }
    },
    beforeUpdate(){
        let error = this.$store.getters.getError || false;
        this.$store.commit('clearError');
        if(error) this.showErrorModal(error);
    },
    mounted(){
        this.traerListaTiposDoc();
    }
}
</script>

<style>

</style>