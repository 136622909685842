<template>
    <div>
        <div v-if="loading" class="d-flex justify-content-center mt-5">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <div v-else>
            <b-card header-tag="header" >
                <template #header>
                    <div>
                        <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                            <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                        </b-button>
                        <div class="float-left table-panel-title mr-5">Proveedores</div>
                        <div class="float-right">
                            <b-button-group size="sm">
                                <b-button variant="secondary" @click="visibleFiltros = !visibleFiltros">Filtros</b-button>
                                <b-button variant="primary" @click="agregar">Agregar</b-button>
                            </b-button-group>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <b-collapse id="collapse-4" v-model="visibleFiltros" class="mt-4">
                        <div class="row">
                            <div class="col-sm-12 col-lg-6 mb-3">
                                <b-input-group prepend="Keyword">
                                    <b-form-input
                                        type="text" 
                                        name="filtros-keyword" 
                                        v-model.trim="filtros.keyword"
                                        class="form-control" 
                                        :disabled = "loading.general"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="col-sm-12 col-lg-6 mb-3">
                                <b-input-group prepend="Pais">
                                    <b-form-select
                                        type="text" 
                                        name="filtros-pais" 
                                        v-model.trim="filtros.pais"
                                        :options="paises"
                                        class="form-control"  
                                        :disabled = "loading.general"
                                    >
                                        <template #first>
                                            <b-form-select-option value="">Todos</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>
                        </div>
                        
                    </b-collapse>
                    
                </template>
                
                

                <ul class="list-group">
                    <li class="list-group-item" v-for="proveedor in arrProveedoresFiltrados" :key="proveedor.id">
                        <div class="row">
                            <div class="col-10">   
                                <div class="float-left mr-3 pointer">
                                    <a :href="proveedor.web" target="_blank">
                                        <img :src="proveedor.img" class="img-thumbnail" alt="Imagen proveedor">
                                    </a>
                                </div>
                                <div>
                                    <span class="title-proveedor">{{proveedor.nombre}}</span>
                                    <span> - Pais: {{proveedor.pais}} - Estado: {{proveedor.estado}}</span>
                                    <b-badge :variant="proveedor.pick_ups ? 'success' : 'danger'" class="float-right">Pick Ups</b-badge>
                                    <div class="row">
                                        <div class="col-12">
                                            <span>Web: <a :href="proveedor.web" target="_blank" rel="noopener noreferrer">{{ proveedor.web }}</a></span>
                                        </div>
                                        <div class="col-12 col-md-5"> 
                                            <u>Contactos</u>
                                            <div class="datos-proveedor">
                                                <div v-for="contacto in proveedor.ContactoProveedors" :key="contacto.id">
                                                    <span><b>{{contacto.descripcion}}</b></span> - <span>{{contacto.tipo}} : {{contacto.valor}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-7"> 
                                            <u>Comentarios</u>
                                            <div class="datos-proveedor">
                                                <p>{{proveedor.comentarios}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <template>
                                    <b-dropdown variant="outline-ligth" class="float-right card-dropdown">
                                        <b-dropdown-item variant="warning" @click="editar(proveedor.id)">
                                            <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                                            Editar
                                        </b-dropdown-item>
                                        <b-dropdown-divider v-if="proveedor.borrable"></b-dropdown-divider>
                                        <b-dropdown-item variant="danger" v-if="proveedor.borrable" @click="checkBorrar(proveedor.id)">
                                            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                            Borrar
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </div>
                        </div>
                    </li>
                </ul>

            </b-card>

            


        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            visibleFiltros: false,
            filtros: {
                keyword: '',
                pais: ''
            },
            proveedores: [],
            paises: [],
        }
    },
    computed: {
        arrProveedoresFiltrados() {
            return this.proveedores.filter(element => {
                let passPais = true;
                let passKey = true;
                if(this.filtros.pais != "" && element.pais != this.filtros.pais) passPais = false;
                if(this.filtros.keyword != ""){
                    passKey = false;
                    let key = this.filtros.keyword.toLowerCase();
                    let desc = element.nombre.toLowerCase();
                    let comentarios = element.comentarios.toLowerCase();
                    if(desc.includes(key)) passKey = true;
                    if(comentarios.includes(key)) passKey = true;
                }
                if(passPais && passKey) return element;
            });
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getProveedores(){
            try {
                let response = await this.$http.get('negocio/proveedor', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener proveedores');

                this.proveedores = response.body.data;
                this.paises =[];
                this.proveedores.forEach(element => {
                    if(this.paises.indexOf(element.pais) == -1) this.paises.push(element.pais);
                });
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        checkBorrar(id){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro de borrar el proveedor?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Borrar',
                cancelButtonText: 'No',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.borrar(id);
                    }
                })
        },
        async borrar(id){
            try {
                let response = await this.$http.delete(`negocio/proveedor/${id}`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al borrar proveedor');

                this.loading = true;
                await this.getProveedores();
                this.loading = false;

                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'El proveedor fue borrado',
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        agregar(){
            this.$router.push({ name: 'addProveedor' });
        },
        editar(id){
            this.$router.push({ name: 'editProveedor' , params: {id:id}});
        }
    },
    async beforeMount(){
        this.loading = true;
        await this.getProveedores();
        this.loading = false;
    }
}
</script>

<style scoped>
    .img-thumbnail {
        max-width: 100px;
        max-height: 100px;
    }

    .title-proveedor{
        font-family: 'Russo One', sans-serif;
        font-size: 1.3em;
    }

    .datos-proveedor{
        font-size: 0.8em;
    }
</style>