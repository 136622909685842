<template>
  <div class="container pt-4" style="max-width: 80%">
      <router-view></router-view>
  </div>
</template>

<script>
export default {
    data(){
        return {}
    }
}
</script>

<style scoped>
</style>