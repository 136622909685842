import Vue from 'vue';
import VueRouter from 'vue-router';

//Components
import LogIn from '../components/LogIn/LogIn';
import Home from '../components/Home/Home';
import Perfil from '../components/Perfil/Perfil';
import Error from '../components/Misc/Error';
import Forbidden from '../components/Misc/Forbidden';

import AdminUsuarios from '../components/Usuarios/index';
import ListUsuarios from '../components/Usuarios/ListUsuarios';
import AddUsuarios from '../components/Usuarios/AddUsuarios';
import EditUsuarios from '../components/Usuarios/EditUsuarios';
import PermisosUsuario from '../components/Usuarios/PermisosUsuario';

import AdminProveedores from '../components/Proveedores/index';
import ListProveedores from '../components/Proveedores/ListProveedores';
import AddProveedores from '../components/Proveedores/AddProveedores';
import EditProveedores from '../components/Proveedores/EditProveedores';

import AdminArticulos from '../components/Articulos/index';
import ListArticulos from '../components/Articulos/ListArticulos';
import AddArticulos from '../components/Articulos/AddArticulos';

import AdminProductos from '../components/Productos/index';
import ListProductos from '../components/Productos/ListProductos';
import ArticulosProductos from '../components/Productos/ArticulosProductos';

import AdminCuentasMeli from '../components/CuentasMeli/index';
import ListCuentas from '../components/CuentasMeli/ListCuentas';

import AdminCategoriasFiscales from '../components/CategoriasFiscales/index';
import ListCategoriasFiscales from '../components/CategoriasFiscales/ListCategoriasFiscales';

import ListCrons from '../components/Crons/ListCrons';

//Middleware
import VueRouteMiddleware from 'vue-route-middleware';
import Auth from './middelwares/auth';
import Guest from './middelwares/guest';
import Permisos from './middelwares/permisos';

Vue.use(VueRouter);

const routes = [
    { path: '/login', name: 'login', component: LogIn, meta: { middleware: Guest } },
    { path: '/', name: 'root', component: Home, meta: { middleware: [Auth, Permisos] } },
    { path: '/home', name: 'home', component: Home, meta: { middleware: [Auth, Permisos] } },
    { path: '/perfil', name: 'perfil', component: Perfil, meta: { middleware: [Auth] } },
    { path: '/crons', name: 'listCrons', component: ListCrons, meta: { middleware: [Auth, Permisos] } },
    {
        path: '/usuarios',
        component: AdminUsuarios,
        meta: { middleware: [Auth, Permisos] },
        children: [
            { path: '', name: 'listUsuarios', component: ListUsuarios },
            { path: 'agregar', name: 'addUsuarios', component: AddUsuarios },
            { path: ':id/editar', name: 'editUsuarios', component: EditUsuarios },
            { path: ':id/permisos', name: 'permisosUsuarios', component: PermisosUsuario },
        ]
    },
    {
        path: '/proveedores',
        component: AdminProveedores,
        meta: { middleware: [Auth, Permisos] },
        children: [
            { path: '', name: 'listProveedores', component: ListProveedores },
            { path: 'agregar', name: 'addProveedor', component: AddProveedores },
            { path: ':id/editar', name: 'editProveedor', component: EditProveedores },
        ]
    },
    {
        path: '/articulos',
        component: AdminArticulos,
        meta: { middleware: [Auth, Permisos] },
        children: [
            { path: '', name: 'listArticulos', component: ListArticulos },
            { path: 'agregar', name: 'addArticulo', component: AddArticulos },
            // { path: ':id/editar', name: 'editProveedor', component: EditProveedores },
        ]
    },
    {
        path: '/productos',
        component: AdminProductos,
        meta: { middleware: [Auth, Permisos] },
        children: [
            { path: '', name: 'listProductos', component: ListProductos },
            { path: ':id/articulos-rel', name: 'articulosProductos', component: ArticulosProductos }
        ]
    },
    {
        path: '/cuentas-meli',
        component: AdminCuentasMeli,
        meta: { middleware: [Auth, Permisos] },
        children: [
            { path: '', name: 'listCuentasMeli', component: ListCuentas },
        ]
    },
    {
        path: '/categorias-fiscales',
        component: AdminCategoriasFiscales,
        meta: { middleware: [Auth, Permisos] },
        children: [
            { path: '', name: 'listCategoriasFiscales', component: ListCategoriasFiscales },
        ]
    },

    { path: '/forbidden', name: 'forbidden', component: Forbidden, meta: { middleware: Auth } },
    { path: '*', name: 'error', component: Error, meta: { middleware: Auth } },
];

export const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach(VueRouteMiddleware());