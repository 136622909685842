<template>
    <div>
        <b-card header-tag="header" >
            <template #header>
                <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">Agregar Proveedor</div>
            </template>
            
            <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">

                <div class="row">

                    <!-- Nombre -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Nombre">
                            <b-form-input
                                type="text" 
                                name="nombre" 
                                v-model.trim="formdata.nombre"
                                @blur="$v.formdata.nombre.$touch()"
                                :class="{error : $v.formdata.nombre.$error}"
                                class="form-control" 
                                autofocus 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.nombre.$error">
                            <small v-if="!$v.formdata.nombre.required">Este campo es obligatorio</small>
                            <small v-else-if="!$v.formdata.nombre.minLength">Este campo debe tener al menos 4 caracteres</small>
                            <small v-else-if="$v.formdata.nombre.maxLength">Este campo debe tener maximo 100 caracteres</small>
                        </div>
                    </div>

                    <!-- Web -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Web">
                            <b-form-input
                                type="text" 
                                name="web" 
                                v-model.trim="formdata.web"
                                @blur="$v.formdata.web.$touch()"
                                :class="{error : $v.formdata.web.$error}"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.web.$error">
                            <small v-if="!$v.formdata.web.required">Este campo es obligatorio</small>
                            <small v-else-if="!$v.formdata.web.minLength">Este campo debe tener al menos 4 caracteres</small>
                            <small v-else-if="$v.formdata.web.maxLength">Este campo debe tener maximo 100 caracteres</small>
                        </div>
                    </div>

                    <!-- Pais -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Pais">
                            <b-form-select
                                type="text" 
                                name="pais" 
                                v-model.trim="formdata.pais"
                                @blur="$v.formdata.pais.$touch()"
                                @change="getEstadosByPais"
                                :class="{error : $v.formdata.pais.$error}"
                                :options="paises"
                                class="form-control"  
                                :disabled = "loading"
                            >
                                <template #first>
                                    <b-form-select-option value="" disabled>Seleccionar un pais</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.pais.$error">
                            <small v-if="!$v.formdata.pais.required">Este campo es obligatorio</small>
                        </div>
                    </div>

                    <!-- Estado -->
                    <div class="col-sm-12 col-lg-6 mb-3">
                        <b-input-group prepend="Estado">
                            <b-form-select
                                type="text" 
                                name="estado" 
                                v-model.trim="formdata.estado"
                                @blur="$v.formdata.estado.$touch()"
                                :class="{error : $v.formdata.estado.$error}"
                                :options="estados"
                                class="form-control"  
                                :disabled = "loading || !formdata.pais"
                            >
                                <template #first>
                                    <b-form-select-option value="" disabled>Seleccionar un estado</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.pais.$error">
                            <small v-if="!$v.formdata.pais.required">Este campo es obligatorio</small>
                        </div>
                    </div>

                    <!-- Pick Ups -->
                    <div class="col-12 col-lg-3 mb-3">
                        <b-input-group prepend="Pick Ups">
                            <b-form-select
                                type="text" 
                                name="pick_ups" 
                                v-model.trim="formdata.pick_ups"
                                :options="booleanOps"
                                class="form-control"  
                                :disabled = "loading"
                            >
                            </b-form-select>
                        </b-input-group>
                    </div>

                    <!-- Imagen -->
                    <div class="col-sm-12 col-lg-9 mb-3">
                        <b-input-group prepend="Imagen">
                            <b-form-file
                                name="file_img" 
                                v-model="formdata.file_img"
                                placeholder="Seleccionar una imagen"
                                class="form-control" 
                                :disabled = "loading"
                                :class="{error : $v.formdata.file_img.$error}"
                                @change="checkFileImgExt"
                            ></b-form-file>
                        </b-input-group>
                        <div class="errorLabel" v-if="$v.formdata.file_img.$error || !validatefile.size || !validatefile.type">
                            <small v-if="!$v.formdata.file_img.required">Este campo es obligatorio</small>
                            <small v-if="!validatefile.size">El archivo debe ser menor a 20MB</small>
                            <small v-else-if="!validatefile.type">El archivo debe ser png o jpg</small>
                        </div>
                    </div>
                    
                    <!-- Comentarios -->
                    <div class="col-sm-12 col-lg-12 mb-5">
                        <b-input-group prepend="Comentarios">
                            <b-form-textarea
                                v-model="text"
                                name="Comentarios" 
                                v-model.trim="formdata.comentarios"
                                rows="3"
                                class="form-control"  
                                :disabled = "loading"
                            ></b-form-textarea>
                        </b-input-group>
                    </div>

                    <!-- Contactos -->
                    <div class="col-sm-12 col-lg-12 mb-2" v-if="!loading">
                        <b-card-group deck>
                            <b-card header-tag="header">
                                <template #header>
                                    <span class="mb-0 float-left">Contactos</span>
                                    <b-button class="btn-sm float-right mr-3 p-0" @click="agregarContacto()" variant="primary">
                                        <b-icon icon="plus" font-scale="1"></b-icon>
                                    </b-button>
                                </template>

                                <b-alert v-model="alertComentarios" variant="danger" dismissible>
                                    Verificar que los todos los campos esten completos!
                                </b-alert>

                                <div v-for="(contacto, index) in formdata.contactos" :key="index" class="mb-2">
                                    <div class="row">
                                        <div class="row col-12 col-lg-11">
                                            <div class="col-12 col-lg-4">
                                                <b-input-group prepend="Tipo">
                                                    <b-form-select
                                                        type="text" 
                                                        v-model.trim="contacto.tipo"
                                                        :options="tiposContactoOps"
                                                        class="form-control"  
                                                    >
                                                    </b-form-select>
                                                </b-input-group>
                                            </div>
                                            <div class="col-12 col-lg-4">
                                                <b-input-group prepend="Desc">
                                                    <b-form-input
                                                        type="text" 
                                                        v-model.trim="contacto.descripcion"
                                                        class="form-control" 
                                                    >
                                                    </b-form-input>
                                                </b-input-group>
                                            </div>
                                            <div class="col-12 col-lg-4">
                                                <b-input-group prepend="Valor">
                                                    <b-form-input
                                                        type="text" 
                                                        v-model.trim="contacto.valor"
                                                        class="form-control" 
                                                    >
                                                    </b-form-input>
                                                </b-input-group>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-1 p-0">
                                            <b-button class="float-right" @click="eliminarContacto(index)" variant="danger" size="sm" style="margin-top: 3px;">
                                                <b-icon icon="trash-fill" font-scale="1"></b-icon>
                                            </b-button>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </b-card>
                        </b-card-group>
                    </div>
                    

                </div>
                
                <div class="float-right mt-5">
                    <b-button type="reset" variant="danger" :disabled = "loading">Reset</b-button>
                    <b-button type="submit" variant="primary" class="ml-2" :disabled = "loading">Guardar</b-button>
                    
                </div>
                
            </b-form>

        </b-card>
    </div>  
    
</template>

<script>
const { required, minLength, maxLength } = require('vuelidate/lib/validators');

export default {
  data(){
        return {
            loading: false,
            paises: this.$store.getters.getListaPaises,
            estados: [],
            file_ext: ['image/jpeg','image/png'],
            formdata: {
                nombre: '',
                web: '',
                pais: '',
                estado: '',
                comentarios: '',
                contactos : [],
                pick_ups: false,
                file_img: null
            },
            booleanOps: [{value: false, text: "No"},{value: true, text: "Si"}],
            tiposContactoOps: [{value: "MAIL", text: "Mail"},{value: "TELL", text: "Tell"}],
            validatefile:{
                size: true,
                type: true
            },
            alertComentarios: false

        }
    },
    validations: {
        formdata:{
            nombre: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(100),
            },
            web: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(100),
            },
            pais: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(150),
            },
            estado: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(150),
            },
            file_img: {
                required
            }

        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        checkLoading(){
            let loading = false;
            for (let key in this.loading){
                if(key !== 'general'){
                    if(this.loading[key]) loading = true;
                }
            }
            this.loading.general = loading;
        },
        async onSubmit(){
            this.loading = true;
            this.$v.$touch();
            if(!this.$v.$invalid && this.validatefile.size && this.validatefile.type && this.checkContactos()){
                console.log(this.formdata);
                let formData = new FormData();
                formData.append('nombre', this.formdata.nombre);
                formData.append('web', this.formdata.web);
                formData.append('pais', this.formdata.pais);
                formData.append('estado', this.formdata.estado);
                formData.append('comentarios', this.formdata.comentarios);
                formData.append('pick_ups', this.formdata.pick_ups);
                formData.append('file_img', this.formdata.file_img);

                try {
                    let response = await this.$http.post('negocio/proveedor', formData , { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken , 'Content-Type': 'multipart/form-data'}});
                    console.log("Rta Add Proveedor: ",response);
                    if(!response.body.success) throw new Error('Error al agregar proveedor');

                    await this.$http.put(`negocio/proveedor/${response.body.data.id}/contacto/array`, {contactos:this.formdata.contactos }, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken}});
                    
                    this.onReset();
                    this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Proveedor Creado',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } catch (error) {
                    let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                    this.showErrorModal(message)
                }
            }
            this.loading = false;
            return false;
        },
        onReset(){
            this.tipos_doc  = [];
            this.formdata = {
                nombre: '',
                web: '',
                pais: '',
                estado: '',
                comentarios: '',
                contactos : [],
                pick_ups: false,
                file_img: null,
                img:''
            };
            this.alertComentarios= false;
            this.$nextTick(() => { this.$v.$reset() })
        },
        checkFileImgExt(e){
            const files =  e.target.files || e.dataTransfer.files;
            const file = files[0];
            this.validatefile.size = true;
            this.validatefile.type = true;
            if(file){
                if(file.size > 20971520){//20MB
                    this.validatefile.size = false;
                }
                if(!this.file_ext.includes(file.type)){
                    this.validatefile.type = false;
                }
            }
        },
        getEstadosByPais(){
            this.estados = this.$store.getters.getListaEstados(this.formdata.pais);
        },
        agregarContacto(){
            let contacto = {
                'tipo': 'MAIL',
                'descripcion': '',
                'valor': '',
            }
            this.formdata.contactos.push(contacto);
        },
        eliminarContacto(index){
            this.formdata.contactos.splice(index, 1);
        },
        checkContactos(){
            let valido = true;
            this.formdata.contactos.forEach(element => {
                if(!element.descripcion || !element.valor){
                    this.alertComentarios = true;
                    valido = false;
                } 
            });
            return valido;
        }
    }
}
</script>

<style>

</style>