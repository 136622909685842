<template>
  <b-modal ref="modalBuscarArt" size="lg" centered title="Buscar Articulos">
    <div v-if="loading" class="d-flex justify-content-center mt-5">
        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div v-else>
        <!-- Buscador -->
        <div>
          <div class="row">
            <!-- Marca -->
            <div class="col-sm-12 col-lg-4 mb-3">
                <b-input-group prepend="Marca" size='sm'>
                    <b-form-select
                        type="text" 
                        v-model.trim="filtros.marca"
                        :options="marcas"
                        class="form-control"  
                        :disabled = "loading"
                    >
                        <template #first>
                            <b-form-select-option value="">Todas</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-input-group>
            </div>

            <!-- Modelo -->
            <div class="col-sm-12 col-lg-4 mb-3">
              <b-input-group prepend="Modelo" size='sm'>
                <b-form-input
                  type="text" 
                  v-model.trim="filtros.modelo"
                  :options="marcas"
                  class="form-control"  
                  :disabled = "loading"
                >

                </b-form-input>
              </b-input-group>
            </div>

            <!-- Btn Buscar -->
            <div class="col-sm-12 col-lg-4 mb-3">
                <b-button class="float-right mb-2" size="sm" @click="buscarArticulos()" variant="primary">
                    Buscar
                </b-button>
            </div>

          </div>
        </div>

        <hr>

        <!-- Resultados -->
        <div>
          <div class="d-flex flex-row flex-wrap justify-content-between align-items-center item-row mb-2" v-for="(articulo, index) in arrArticulos" :key="`articulos-rel-${index}`">
              <!-- Imagenes -->
              <div style="width: 100px; padding: 10px; background-color: #fff; border: 1px solid #dee2e6; border-radius: 0.25rem;" class="text-center">
                  <template> 
                      <img 
                          :src="getUrlImg(articulo.mini_img)" 
                          :alt="articulo.titulo" 
                          style="max-width: 80px; height: 80px;"
                          onerror="javascript:this.src='https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png'"
                      />
                  </template> 
              </div>
              <!-- Datos Producto -->
              <div class="row" style="flex-grow: 1; padding: 1em;">
                  <div class="col-5 d-flex flex-column datos-item" >
                      <span class="mb-1"><b>ID: </b>
                        {{ articulo.id }}
                        <span v-if="checkArticuloRelacionado(articulo)" > (Relacionado)</span>
                      </span>
                      <span class="mb-1"><b>Marca: </b>{{ articulo.marca }}</span>
                      <span class="mb-1"><b>Modelo: </b>{{ articulo.modelo }}</span>
                      <span class="mb-1"><b>Proveedor: </b>{{ articulo.Proveedor ?  articulo.Proveedor.nombre : 'N/A'}}</span>
                      <span class="mb-1"><b>SKU: </b>
                          <a :href="articulo.link_item_proveedor" target="_blank" rel="noopener noreferrer">{{ articulo.sku_proveedor }}</a>
                      </span>
                      <span class="mb-1"><b>Producto Relacionado: </b>
                          <a 
                            v-if="articulo.id_producto"
                            :href="`/productos/${articulo.id_producto}/articulos-rel`" 
                            target="_blank" 
                            rel="noopener noreferrer">
                            {{ articulo.id_producto }}
                          </a>
                          <span v-else> - </span>
                      </span>
                  </div>
                  <div class="col-4 datos-item">
                      <div>
                          <b-badge class="mr-2 mb-2" :variant="articulo.categorias_proveedor && articulo.categorias_proveedor.length ? 'success' : 'danger'">Categorias ({{articulo.categorias_proveedor ? articulo.categorias_proveedor.length : '0'}})</b-badge>
                          <b-badge class="mr-2 mb-2" :variant="articulo.imgs.length ? 'success' : 'danger'">Img ({{articulo.imgs.length}})</b-badge>
                      </div>
                      <div>
                          <b-badge class="mr-2 mb-2" variant="danger" v-if="articulo.error">Error</b-badge>
                          <b-badge class="mr-2 mb-2" variant="danger" v-if="articulo.revisar">Revisar</b-badge>
                      </div>
                  </div>
                  <div class="col-3">
                    <!-- Action Btn -->
                    <div class="flex flex-col float-right">
                        <b-button 
                          variant="primary" 
                          block 
                          size="sm"
                          :disabled="checkArticuloRelacionado(articulo)"
                          @click="relacionarPosibleArticuloToProducto(articulo)"
                        >
                          <span v-if="!checkArticuloRelacionado(articulo)">Vincular</span>
                          <span v-else>Ya vinculado</span>
                        </b-button>
                    </div>
                  </div>
              </div>
              
          </div>

          <b-pagination
              v-if="arrArticulos.length > 0"
              v-model="pagination.page"
              :total-rows="pagination.count"
              :per-page="pagination.limit"
              align="center"
              first-number
              last-number
              @change="handlePageChange"
          ></b-pagination>

        </div>
    </div>
    <template #modal-footer>
        <div class="w-100 p-2">
          <b-button
            variant="danger"
            size="sm"
            @click="hideModal()"
            class="w-100"
          >
            Cerrar
          </b-button>
        </div>
    </template>
  </b-modal>
</template>

<script>
    export default {
        data(){
            return {
              articulosRelacionados: [],
              arrArticulos: [],
              marcas: [],
              filtros:{
                marca: '',
                modelo:''
              },
              pagination:{
                  page: 1,
                  limit: 5,
                  count: 0,
              },
              producto: null,
              loading: false,
              loadingRelacionar: false,
              show: false
            }
        },
        props: [ 'refreshArticulos' ],
        methods: {
            async buscarArticulos(){
              try {
                this.loading = true;
                this.arrArticulos = [];
                let query = `articulo?marca=${this.filtros.marca}&modelo=${this.filtros.modelo}&page=${this.pagination.page}&limit=${this.pagination.limit}`;

                let response = await this.$http.get(query, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener articulos');

                console.log('Articulos: ',response.body.data);
                this.arrArticulos = response.body.data.rows;

                this.pagination.page = response.body.data.page;
                this.pagination.limit = response.body.data.limit;
                this.pagination.count = response.body.data.count;

                this.loading = false;

              } catch (error) {
                  let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                  console.log(message)
              }
            },
            async showModal(marcas,articulosRelacionados,producto) {
              this.articulosRelacionados = articulosRelacionados;
              this.marcas = marcas;
              if(this.producto != producto || !this.arrArticulos.length){
                this.producto = producto;
                this.filtros.marca = producto.marca;
                this.filtros.modelo = producto.modelo;
                await this.buscarArticulos();   
              }
              this.$refs.modalBuscarArt.show();
            },
            hideModal() {
              this.$refs.modalBuscarArt.hide()
            },
            getUrlImg(url){
                if(url) return url;
                return 'https://mkrakenfiles.nyc3.digitaloceanspaces.com/assets/noImg.png';
            },
            async handlePageChange(value){
                this.loading = true;
                this.pagination.page = value;
                await this.buscarArticulos();
                this.loading = false;
            },
            checkArticuloRelacionado(articulo){
              let articuloRel = this.articulosRelacionados.find(element => element.id === articulo.id);
              if(articuloRel) return true;
              return false;
            },
            async relacionarPosibleArticuloToProducto(articulo){
              console.log('Relacionar Articulo: ',articulo)
              try {
                this.loadingRelacionar = true;
                let response = await this.$http.post(`articulo/${articulo.id}/posible-relacion-producto`,{id_producto: this.producto.id}, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al relacionar articulo');
                
                this.$swal.fire({
                    icon: 'success',
                    title: 'Articulo Relacionado',
                    timer: 1500
                });

                this.refreshArticulos();
                this.loadingRelacionar = false;

              } catch (error) {
                  let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                  console.log(message)
                  this.loadingRelacionar = false;
              }
              
            }
        }
    }
</script>

<style>
</style>