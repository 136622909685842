<template>
  <div class="container pt-4">
        <div>
            <b-card header-tag="header" >
                <template #header>
                    <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                        <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                    </b-button>
                    <div class="float-left table-panel-title">Perfil Usuario</div>
                </template>
                
                <div v-if="loading" class="d-flex justify-content-center mt-5">
                    <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <b-img thumbnail fluid :src="dataUsuario.img" alt="Image 1" style="max-width: 200px"></b-img>
                        </div>
                        <div class="col-12 col-md-9">
                            <b>Apellido y Nombre: </b> {{dataUsuario.nombre}}, {{dataUsuario.apellido}} <br>
                            <b>ID: </b> {{dataUsuario.id}} - <b>Usuario: </b> {{dataUsuario.usuario}} <br>
                            <b>Mail: </b> {{dataUsuario.mail}} - <b>Tell: </b> {{dataUsuario.tell}} <br>
                            <b>Fecha Nacimiento: </b> {{dataUsuario.fecha_nacimiento}} - <b>Fecha Ingreso: </b> {{dataUsuario.fecha_ingreso}} <br>
                            <b>Pais: </b> {{dataUsuario.pais}} - <b>Direccion: </b> {{dataUsuario.direccion}} <br>
                            <b>{{dataUsuario.tipo_doc}}: </b> {{dataUsuario.documento}} <br>
                        </div>
                    </div>
                    
                </div>
                
            </b-card>
        </div>  
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data(){
        return {
            usuario : null,
            loading: true,
            permisos : null,
            dataUsuario: {
                id : null,
                nombre: '',
                apellido: '',
                usuario: '',
                mail: '',
                fecha_nacimiento: '',
                pais: 'Argentina',
                tipo_doc: '',
                documento: '',
                direccion: '',
                fecha_ingreso: '',
                tell: '',
                img: false,
                status: false,
                file_img: null
            },
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        getDatosUsuario(){
        let id = this.usuario.id || false;
        this.loading = true;
        if(id){
            this.$http.get(`user-sis/usuario/${id}`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } })
                .then(response => {
                    
                    if (response.body && response.body.success) {   
                        console.log('Data User',response.body.data);
                        this.dataUsuario.id = id;
                        this.dataUsuario.nombre = response.body.data.nombre;
                        this.dataUsuario.apellido = response.body.data.apellido;
                        this.dataUsuario.usuario = response.body.data.usuario;
                        this.dataUsuario.mail = response.body.data.mail;
                        this.dataUsuario.fecha_nacimiento = response.body.data.fecha_nacimiento;
                        this.dataUsuario.pais = response.body.data.pais;
                        this.dataUsuario.tipo_doc = response.body.data.tipo_doc;
                        this.dataUsuario.documento = response.body.data.documento;
                        this.dataUsuario.direccion = response.body.data.direccion;
                        this.dataUsuario.fecha_ingreso = response.body.data.fecha_ingreso;
                        this.dataUsuario.tell = response.body.data.tell;
                        this.dataUsuario.status = response.body.data.status;
                        this.dataUsuario.img = response.body.data.img;
                        this.loading = false;
                    } else {
                        throw new Error('Al traer los datos del usuario');
                    }
                }, response => {
                    let error = 'Al conectar con el servidor';
                    if (response.body && !response.body.success) {
                        error = response.body.err.message || 'Al conectar con el servidor';
                    }
                    this.showErrorModal(error);
                });
        }
    },
    },
    computed:{
        ...mapGetters([
            'getUsuario',
            'getPermisos',
        ])
    },
    beforeMount(){
        this.usuario = this.getUsuario;
        this.permisos = this.getPermisos;
        this.getDatosUsuario();
        console.log("Usuario",this.usuario);
        console.log("Permisos",this.usuario);
    }
}
</script>

<style>

</style>