<template>
  <div id="App" class="app-container">
    <compNavBar v-if="isNotInLogIn() && isNotError() && isNotForbidden()"></compNavBar>
    <router-view></router-view>
  </div>
</template>

<script>
import compNavBar from './components/NavBar/NavBar';

export default {
  name: 'App',
  data(){
    return{
    }
  },
  methods: {
    isNotInLogIn() {
      return this.$router.history.current["name"] != "login";
    },
    isNotError() {
      return this.$router.history.current["name"] != "error";
    },
    isNotForbidden() {
      return this.$router.history.current["name"] != "forbidden";
    }
  },
  mounted(){
    this.$store.dispatch('setListaPaises');
  },
  components: {
    compNavBar
  },
}
</script>

<style>
  @import './assets/css/custom.css';
  body {
    background-color: #d7d7d7 !important;
  }

  .app-container {
      min-width: 411px !important;
  }
</style>
