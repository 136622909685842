<template>
    <div class="container pt-4">
        <b-card header-tag="header" >
            <template #header>
                <div>
                    <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                        <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                    </b-button>
                    <div class="float-left table-panel-title mr-5">Crons</div>
                </div>                    
            </template>
            
            <div class="row mb-3">
                <div class="col-4">
                    <b-button 
                        variant="primary" 
                        @click="refreshData()"
                        size="sm"
                        class="mr-1"
                    >
                        <b-icon icon="arrow-clockwise"></b-icon>
                    </b-button>
                    <b-button 
                        v-if="toggleAll"
                        variant="secondary" 
                        @click="selectAllRows()"
                        size="sm"
                    >
                        All
                    </b-button>
                    <b-button 
                        v-else
                        variant="secondary" 
                        @click="clearSelected()"
                        size="sm"
                    >
                        None
                    </b-button>
                </div>
                <div class="col-8 text-right">
                    <b-button 
                        :disabled="!dataTable.selected.length"
                        class="mr-2" 
                        variant="danger" 
                        @click="cambiarStatusSelectedCron(false)"
                        size="sm"
                    >Pausar</b-button>

                    <b-button 
                        :disabled="!dataTable.selected.length"
                        variant="success" 
                        class="mr-2" 
                        @click="cambiarStatusSelectedCron(true)"
                        size="sm"
                    >Activar</b-button>

                    <b-button 
                        :disabled="!dataTable.selected.length"
                        variant="info" 
                        @click="resetCron()"
                        size="sm"
                    >Reset</b-button>
                </div>
            </div>

            <b-table 

                hover 
                :items="dataTable.items" 
                :fields="dataTable.fields"
                :busy="dataTable.isBusy"
                select-mode="multi"
                responsive="sm"
                selectable
                ref="selectableTable"
                @row-selected="onRowSelected"
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                    </div>
                </template>

                <template #cell(status)="data">
                    <b-badge pill :variant="data.value ? 'success' : 'danger'">{{data.value ? 'Activo' : 'Pausado'}}</b-badge>
                </template>

                <template #cell(inicio)="data">
                    {{ armarFechas(data.value) }}
                </template>

                <template #cell(fin)="data">
                    {{ data.value ? armarFechas(data.value) : 'En Curso'}}
                </template>

            </b-table>

        </b-card>
    </div>
</template>

<script>
export default {
    data(){
        return {
            toggleAll: true,
            dataTable : {
                isBusy: true,
                selected: [],
                fields: [
                    {
                        key: 'cron',
                        label: 'Cron',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true
                    },
                    {
                        key: 'valor',
                        label: 'Valor'
                    },
                    {
                        key: 'duracion',
                        label: 'Duracion',
                        sortable: true
                    },
                    {
                        key: 'inicio',
                        label: 'Inicio',
                        sortable: true
                    },
                    {
                        key: 'fin',
                        label: 'Fin',
                        sortable: true
                    }
                ],
                items: []
            }
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        armarFechas(timestamp){
            let date = new Date(timestamp);     
            let dia = date.getDate() > 9 ? date.getDate() : '0'+date.getDate();    
            let mes = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0'+(date.getMonth() + 1);    
            let anio = date.getFullYear();    
            let hora = date.getHours() > 9 ? date.getHours() : '0'+date.getHours();    
            let minuto = date.getMinutes() > 9 ? date.getMinutes() : '0'+date.getMinutes();    
            let segundo = date.getSeconds() > 9 ? date.getSeconds() : '0'+date.getSeconds();
            return `${anio}/${mes}/${dia} ${hora}:${minuto}:${segundo}`;
        },
        async getCrons(){
            try {
                let response = await this.$http.get('cron/config', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener crons');

                this.dataTable.items = [];
                response.body.data.forEach(element => {
                    this.dataTable.items.push({ 
                        id: element.id, 
                        cron: element.cron, 
                        status: element.status, 
                        valor: element.valor, 
                        duracion: Math.round(element.duracion / 1000), 
                        inicio: element.inicio ? new Date(element.inicio).getTime() : null, 
                        fin: element.fin ? new Date(element.fin).getTime() : null
                    });
                });

                console.log(this.dataTable.items );

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async refreshData(){
            this.dataTable.isBusy = true;
            await this.getCrons();
            this.clearSelected();
            this.dataTable.isBusy = false;
        },
        onRowSelected(items) {
            this.dataTable.selected = items;
        },
        selectAllRows() {
            this.toggleAll = false;
            this.$refs.selectableTable.selectAllRows();
        },
        clearSelected() {
            this.toggleAll = true;
            this.$refs.selectableTable.clearSelected();
        },
        async cambiarStatusSelectedCron(status){
            console.log('Cambiar estado cron',status);

            if(!status){
                const swalWithBootstrapButtons = this.$swal.mixin({
                    customClass: {
                        title: "swal-title",
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger mr-3'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Esta seguro pausar los cron?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No',
                    reverseButtons: true
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.editarCron(status);
                    }
                })
            }else{
                this.editarCron(status);
            }
            
        },
        async editarCron(status){
            try {
                let arrCrons = [];
                this.dataTable.selected.forEach(element => {
                    if(element.status != status) arrCrons.push(element.id);
                });

                let response = await this.$http.put('cron/status', {arrCrons,status} , { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken }});
                console.log("Rta: ",response);
                if(!response.body.success) throw new Error(response.body.err.message);

                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Crons actualizados',
                    showConfirmButton: false,
                    timer: 1500
                });
                await this.refreshData();
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async resetCron(){
            try {
                for (let i = 0; i < this.dataTable.selected.length; i++) {
                    const element = this.dataTable.selected[i];
                    await this.$http.put('cron/reset/'+element.id, {} , { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken }});
                }

                this.refreshData();
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Crons reseteados',
                    showConfirmButton: false,
                    timer: 1500
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
    },
    async beforeMount(){
        await this.refreshData();
    }
}
</script>

<style scoped>
.table-active{
    background-color: rgb(27 49 255 / 8%);
}
</style>