import Vue from 'vue';
import Vuex from 'vuex';
import jwt_decode from "jwt-decode";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        usuario: null,
        exp: null,
        token: null,
        permisos: null,
        paises: [],
        estados: [],
        error: false
    },
    getters: {
        getUsuario(state) {
            return state.usuario;
        },
        getToken(state) {
            return state.token;
        },
        getPermisos(state) {
            return state.permisos;
        },
        getListaPaises(state) {
            return state.paises;
        },
        getListaEstados(state) {
            return (pais) => {
                if (!pais) return [];
                return state.estados[pais];
            };
        },
        getError(state) {
            return state.error;
        }
    },
    actions: {
        setListaPaises({ commit }) {
            fetch('https://countriesnow.space/api/v0.1/countries/states')
                .then(response => response.json())
                .then((data) => {
                    data.data.forEach((element) => {
                        commit('pushPais', { pais: element.name });
                        commit('pushEstados', { pais: element.name, estados: element.states });
                    });
                    console.log('Paises cargados');
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
    mutations: {
        setToken(state, token) {
            localStorage.setItem('jwt-token', token);
            let datosToken = jwt_decode(token);
            state.exp = datosToken.exp;
            state.token = token;
            state.permisos = datosToken.permisos;
            state.usuario = datosToken.usuario;
        },
        removeToken(state) {
            localStorage.removeItem('jwt-token');
            state.exp = null;
            state.token = null;
            state.permisos = null;
            state.usuario = null;
        },
        checkStoreToken(state) {
            let token = localStorage.getItem('jwt-token');
            if (token) {
                let datosToken = jwt_decode(token);
                state.exp = datosToken.exp;
                state.token = token;
                state.permisos = datosToken.permisos;
                state.usuario = datosToken.usuario;
            }
        },
        pushPais(state, payload) {
            state.paises.push(payload.pais);
        },
        pushEstados(state, payload) {
            let estados = [];
            payload.estados.forEach(element => {
                estados.push(element.name);
            });
            state.estados[payload.pais] = estados;
        },
        clearError(state) {
            state.error = false;
        },
        setError(state, payload) {
            state.error = payload;
        }
    }
});