import { store } from '../../store/store';

export default (to, from, next) => {
    let permisos = JSON.parse(JSON.stringify(store.getters.getPermisos)) || false;

    let valido = true;

    if (!permisos) {
        return next({ name: 'login', params: { error: 'No tiene permisos para el sistema' } });
    }
    if (permisos) {
        switch (to.name) {
            case 'root':
                if (!permisos['HOME'] || !permisos['HOME'].V) valido = false;
                break;
            case 'home':
                if (!permisos['HOME'] || !permisos['HOME'].V) valido = false;
                break;


            case 'listUsuarios':
                if (!permisos['USUARIOS'] || !permisos['USUARIOS'].V) valido = false;
                break;
            case 'addUsuarios':
                if (!permisos['USUARIOS'] || !permisos['USUARIOS'].A) valido = false;
                break;
            case 'editUsuarios':
                if (!permisos['USUARIOS'] || !permisos['USUARIOS'].M) valido = false;
                break;
            case 'permisosUsuarios':
                if (!permisos['USUARIOS'] || !permisos['USUARIOS'].M) valido = false;
                break;


            case 'ListProveedores':
                if (!permisos['PROVEEDORES'] || !permisos['PROVEEDORES'].V) valido = false;
                break;
            case 'addProveedor':
                if (!permisos['PROVEEDORES'] || !permisos['PROVEEDORES'].A) valido = false;
                break;
            case 'editProveedor':
                if (!permisos['PROVEEDORES'] || !permisos['PROVEEDORES'].M) valido = false;
                break;



            case 'listCuentasMeli':
                if (!permisos['CUENTAS-MELI'] || !permisos['CUENTAS-MELI'].V) valido = false;
                break;


            case 'listCrons':
                if (!permisos['CRONS'] || !permisos['CRONS'].V) valido = false;
                break;


            case 'ListArticulos':
                if (!permisos['ARTICULOS'] || !permisos['ARTICULOS'].V) valido = false;
                break;
            case 'addArticulo':
                if (!permisos['ARTICULOS'] || !permisos['ARTICULOS'].A) valido = false;
                break;

            case 'ListProductos':
                if (!permisos['PRODUCTOS'] || !permisos['PRODUCTOS'].V) valido = false;
                break;

            default:
                break;
        }
    }

    if (!valido) {
        return next({ name: 'forbidden' });
    } else {
        return next();
    }


};