<template>
    <div>
        <b-card header-tag="header" class="mb-5">
            <template #header>
                <div>
                    <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                        <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                    </b-button>
                    <div class="float-left table-panel-title mr-5">Agregar Articulo</div>
                </div>               
            </template>
            
            <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">

                <div class="row">
                    <!-- Proveedor -->
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <b-input-group prepend="Proveedor">
                            <b-form-select
                                type="text" 
                                name="id_proveedor" 
                                v-model.trim="formdata.id_proveedor"
                                :options="proveedores"
                                class="form-control"  
                                :disabled = "loading"
                            >
                                <template #first>
                                    <b-form-select-option value="" disabled>Seleccionar un proveedor</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                    </div>

                    <!-- sku_proveedor -->
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <b-input-group prepend="SKU Proveedor">
                            <b-form-input
                                type="text" 
                                name="sku_proveedor" 
                                v-model.trim="formdata.sku_proveedor"
                                class="form-control"  
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <!-- link_item_proveedor -->
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <b-input-group prepend="URL">
                            <b-form-input
                                type="text" 
                                name="link_item_proveedor" 
                                v-model.trim="formdata.link_item_proveedor"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <!-- marca -->
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <b-input-group prepend="Marca">
                            <b-form-input
                                type="text" 
                                name="marca" 
                                v-model.trim="formdata.marca"
                                @keyup="addToTitle()"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <!-- modelo -->
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <b-input-group prepend="Modelo">
                            <b-form-input
                                type="text" 
                                name="modelo" 
                                v-model.trim="formdata.modelo"
                                @keyup="addToTitle()"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <!-- titulo -->
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <b-input-group prepend="Titulo">
                            <b-form-input
                                type="text" 
                                name="titulo" 
                                v-model.trim="formdata.titulo"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <hr style="width: 100%; margin-bottom: 2rem;">

                    <!-- precio_lista -->
                    <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
                        <b-input-group prepend="Precio">
                            <b-form-input
                                type="number" 
                                name="precio_lista" 
                                v-model.trim="formdata.precio_lista"
                                class="form-control" 
                                :disabled = "loading"
                                step="0.01"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <!-- Moneda -->
                    <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
                        <b-input-group prepend="Moneda">
                            <b-form-select
                                type="text" 
                                name="precio_lista_moneda" 
                                v-model.trim="formdata.precio_lista_moneda"
                                :options="typeMonedas"
                                class="form-control"  
                                :disabled = "loading"
                            >
                                <template #first>
                                    <b-form-select-option value="" disabled>Seleccionar moneda</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                    </div>

                    <!-- disponible -->
                    <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
                        <b-input-group prepend="Disponible">
                            <b-form-select
                                type="text" 
                                name="disponible" 
                                v-model.trim="formdata.disponible"
                                :options="booleanOps"
                                class="form-control"  
                                :disabled = "loading"
                            >
                                <template #first>
                                    <b-form-select-option value="" disabled>Seleccionar moneda</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                    </div>

                    <!-- catidad_paquete -->
                    <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
                        <b-input-group prepend="Cant.Paq.">
                            <b-form-input
                                type="text" 
                                name="catidad_paquete" 
                                v-model.trim="formdata.catidad_paquete"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <!-- stock_proveedor -->
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <b-input-group prepend="Stock">
                            <b-form-input
                                type="number" 
                                name="stock_proveedor" 
                                v-model.trim="formdata.stock_proveedor"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <!-- stock_dispo -->
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <b-input-group prepend="Dispo.Stock" append="días">
                            <b-form-input
                                type="number" 
                                name="stock_dispo" 
                                v-model.trim="formdata.stock_dispo"
                                @keyup="addToDispoStr()"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <!-- stock_proveedor_str -->
                    <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <b-input-group prepend="Dispo.Str">
                            <b-form-input
                                type="text" 
                                name="stock_dispo" 
                                v-model.trim="formdata.stock_proveedor_str"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <hr style="width: 100%; margin-bottom: 2rem;">

                    <!-- Medidas -->
                    <div class="col-12 mb-3">
                        <div class="row">
                            <!-- X -->
                            <div class="col-sm-12 col-md-4">
                                <b-input-group>
                                    <template #prepend>
                                        <b-input-group-text >X</b-input-group-text>
                                    </template>

                                    <b-form-input
                                        type="number" 
                                        name="medidas_value_x" 
                                        v-model.trim="formdata.medidas.x.value"
                                        class="form-control" 
                                        :disabled = "loading"
                                        step="0.01"
                                    ></b-form-input>

                                    <template #append>
                                        <b-dropdown :text="formdata.medidas.x.unit" :disabled = "loading">
                                            <b-dropdown-item 
                                                v-for="(medidaUnit, index) in arrUnidadesMedidas" 
                                                :key="'medidas-x-unit-'+index"
                                                @click="setMedidaUnit('x',medidaUnit.value)"
                                            >{{medidaUnit.value}} </b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                </b-input-group>
                            </div>
                            <!-- Y -->
                            <div class="col-sm-12 col-md-4">
                                <b-input-group>
                                    <template #prepend>
                                        <b-input-group-text >Y</b-input-group-text>
                                    </template>

                                    <b-form-input
                                        type="number" 
                                        name="medidas_value_y" 
                                        v-model.trim="formdata.medidas.y.value"
                                        class="form-control" 
                                        :disabled = "loading"
                                        step="0.01"
                                    ></b-form-input>

                                    <template #append>
                                        <b-dropdown :text="formdata.medidas.y.unit" :disabled = "loading">
                                            <b-dropdown-item 
                                                v-for="(medidaUnit, index) in arrUnidadesMedidas" 
                                                :key="'medidas-y-unit-'+index"
                                                @click="setMedidaUnit('y',medidaUnit.value)"
                                            >{{medidaUnit.value}} </b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                </b-input-group>
                            </div>
                            <!-- Z -->
                            <div class="col-sm-12 col-md-4">
                                <b-input-group>
                                    <template #prepend>
                                        <b-input-group-text >Z</b-input-group-text>
                                    </template>

                                    <b-form-input
                                        type="number" 
                                        name="medidas_value_z" 
                                        v-model.trim="formdata.medidas.z.value"
                                        class="form-control" 
                                        :disabled = "loading"
                                        step="0.01"
                                    ></b-form-input>

                                    <template #append>
                                        <b-dropdown :text="formdata.medidas.z.unit" :disabled = "loading">
                                            <b-dropdown-item 
                                                v-for="(medidaUnit, index) in arrUnidadesMedidas" 
                                                :key="'medidas-z-unit-'+index"
                                                @click="setMedidaUnit('z',medidaUnit.value)"
                                            >{{medidaUnit.value}} </b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                </b-input-group>
                            </div>
                        </div>
                    </div>

                    <!-- Peso -->
                    <div class="col-12 col-md-6 mb-3">
                        <b-input-group>
                            <template #prepend>
                                <b-input-group-text >Peso</b-input-group-text>
                            </template>

                            <b-form-input
                                type="number" 
                                name="peso_value" 
                                v-model.trim="formdata.peso.value"
                                class="form-control" 
                                :disabled = "loading"
                                step="0.01"
                            ></b-form-input>

                            <template #append>
                                <b-dropdown :text="formdata.peso.unit" :disabled = "loading">
                                    <b-dropdown-item 
                                        v-for="(pesoUnit, index) in arrUnidadesPesos" 
                                        :key="'peso-unit-'+index"
                                        @click="setPesoUnit(pesoUnit.value)"
                                    >{{pesoUnit.value}} </b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-input-group>
                    </div>

                    <!-- Codigo -->
                    <div class="col-12 col-md-6 mb-3">
                        <b-input-group>
                            <template #prepend>
                                <b-input-group-text >Codigo</b-input-group-text>
                            </template>

                            <b-form-input
                                type="number" 
                                name="codigo_value" 
                                v-model.trim="formdata.codigo.value"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>

                            <template #append>
                                <b-dropdown :text="formdata.codigo.unit" :disabled = "loading">
                                    <b-dropdown-item 
                                        v-for="(codigoUnit, index) in arrUnidadesCodigos" 
                                        :key="'codigo-unit-'+index"
                                        @click="setCodigoUnit(codigoUnit.value)"
                                    >{{codigoUnit.value}} </b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-input-group>
                    </div>

                    <hr style="width: 100%; margin-bottom: 2rem;">

                    <!-- Manual -->
                    <div class="col-12 mb-3">
                        <b-input-group prepend="Manual">
                            <b-form-input
                                type="text" 
                                name="manual" 
                                v-model.trim="formdata.manual"
                                class="form-control" 
                                :disabled = "loading"
                            ></b-form-input>
                        </b-input-group>
                    </div>

                    <!-- Categorias -->
                    <div class="col-sm-12 col-lg-12 mb-3 mt-2">
                        <b-card-group deck>
                            <b-card header-tag="header">
                                <template #header>
                                    <span class="mb-0 float-left">Categoría</span>
                                </template>

                                <div class="d-flex flex-row justify-content-between mb-3">
                                    <vue-typeahead-bootstrap
                                        v-model="queryCategoria"
                                        :data="arrCategorias"
                                        placeholder="Buscar Categoria"
                                        :minMatchingChars="0"
                                        :showOnFocus="true"
                                        class="flex-fill mr-2"
                                        @hit="agregarCategoria()"
                                        @keyup.enter.prevent="agregarCategoria()"
                                        :disabled = "loading"
                                    />
                                    <b-button @click="agregarCategoria()" variant="primary" size="sm" :disabled = "loading">
                                        <b-icon icon="plus" font-scale="1"></b-icon>
                                    </b-button>
                                </div>
                                

                                <div v-for="(categoria, index) in formdata.categorias" :key="'categorias-'+index" class="mb-2">
                                    <div class="d-flex flex-row justify-content-between">
                                        <b-input-group class="flex-fill mr-2">
                                            <template #prepend>
                                                <b-input-group-text >Categoria {{index}}</b-input-group-text>
                                            </template>
                                            <b-form-input
                                                type="text" 
                                                :value="categoria"
                                                class="form-control"
                                                :disabled = "loading"
                                            >
                                            </b-form-input>
                                        </b-input-group>

                                        <b-button @click="eliminarCategoria(index)" variant="danger" size="sm" :disabled = "loading">
                                            <b-icon icon="trash-fill" font-scale="1"></b-icon>
                                        </b-button>
                               
                                    </div>
                                    
                                </div>
                                
                            </b-card>
                        </b-card-group>
                    </div>

                    <!-- Imagenes -->
                    <div class="col-sm-12 col-lg-12 mb-3 mt-2">
                        <b-card-group deck>
                            <b-card header-tag="header">
                                <template #header>
                                    <span class="mb-0 float-left">Imagenes</span>
                                    <b-button class="float-right mr-3 p-0" size="sm" @click="agregarImagen()" variant="primary" :disabled = "loading">
                                        <b-icon icon="plus" font-scale="1"></b-icon>
                                    </b-button>
                                </template>

                                <b-input-group prepend="Url Principal" class="mb-3">
                                    <b-form-input
                                        type="text" 
                                        v-model.trim="formdata.mini_img"
                                        class="form-control" 
                                        :disabled = "loading"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <div v-for="(url_img, index) in formdata.arr_imgs" :key="'img-'+index" class="mb-2" >
                                    <div class="d-flex flex-row justify-content-between">
                                        <b-input-group prepend="Url Img" class="flex-fill mr-2">
                                            <b-form-input
                                                type="text" 
                                                v-model.trim="url_img.value"
                                                @keydown.enter.prevent="agregarImagen()"
                                                class="form-control" 
                                                :disabled = "loading"
                                            >
                                            </b-form-input>
                                        </b-input-group>

                                        <b-button @click="eliminarImagen(index)" variant="danger" size="sm" :disabled="loading" >
                                            <b-icon icon="trash-fill" font-scale="1"></b-icon>
                                        </b-button>
                               
                                    </div>
                                    
                                </div>
                                
                            </b-card>
                        </b-card-group>
                    </div>

                    <!-- Videos -->
                    <div class="col-sm-12 col-lg-12 mb-3 mt-2">
                        <b-card-group deck>
                            <b-card header-tag="header">
                                <template #header>
                                    <span class="mb-0 float-left">Videos</span>
                                    <b-button class="float-right mr-3 p-0" @click="agregarVideo()" size="sm" variant="primary" :disabled = "loading">
                                        <b-icon icon="plus" font-scale="1"></b-icon>
                                    </b-button>
                                </template>

                                <div v-for="(url_video, index) in formdata.arr_videos" :key="'video-'+index" class="mb-2">
                                    <div class="d-flex flex-row justify-content-between">
                                        <b-input-group prepend="Url Img" class="flex-fill mr-2">
                                            <b-form-input
                                                type="text" 
                                                v-model.trim="url_video.value"
                                                @keydown.enter.prevent="agregarVideo()"
                                                class="form-control" 
                                                :disabled = "loading"
                                            >
                                            </b-form-input>
                                        </b-input-group>

                                        <b-button @click="eliminarVideo(index)" variant="danger" size="sm" :disabled = "loading">
                                            <b-icon icon="trash-fill" font-scale="1"></b-icon>
                                        </b-button>
                               
                                    </div>
                                    
                                </div>
                                
                            </b-card>
                        </b-card-group>
                    </div>                 

                    <!-- Atributos -->
                    <div class="col-sm-12 col-lg-12 mb-3 mt-2">
                        <b-card-group deck>
                            <b-card header-tag="header">
                                <template #header>
                                    <span class="mb-0 float-left">Atributos</span>
                                    <b-button class="float-right mr-3 p-0" @click="agregarAtributo()" size="sm" variant="primary" :disabled = "loading">
                                        <b-icon icon="plus" font-scale="1"></b-icon>
                                    </b-button>
                                </template>

                                <div v-for="(attr, index) in formdata.arr_attrs" :key="'attr-'+index" class="mb-2">
                                    <div class="d-flex flex-row justify-content-between">

                                        <div class="row flex-fill mr-2">
                                            <b-input-group prepend="Key" class="col-6">
                                                <b-form-input
                                                    type="text" 
                                                    v-model.trim="attr.key"
                                                    class="form-control" 
                                                    :disabled = "loading"
                                                >
                                                </b-form-input>
                                            </b-input-group>

                                            <b-input-group prepend="Valor" class="col-6">
                                                <b-form-input
                                                    type="text" 
                                                    v-model.trim="attr.value"
                                                    @keydown.enter.prevent="agregarAtributo()"
                                                    class="form-control" 
                                                    :disabled = "loading"
                                                >
                                                </b-form-input>
                                            </b-input-group>

                                        </div>

                                        <b-button @click="eliminarAtributo(index)" variant="danger" size="sm" :disabled = "loading">
                                            <b-icon icon="trash-fill" font-scale="1"></b-icon>
                                        </b-button>
                               
                                    </div>
                                    
                                </div>
                                
                            </b-card>
                        </b-card-group>
                    </div>     

                    <!-- Descripciones-->
                    <div class="col-sm-12 col-lg-12 mb-3 mt-2">
                        <b-card-group deck>
                            <b-card header-tag="header">
                                <template #header>
                                    <span class="mb-0 float-left">Descripciones</span>
                                </template>

                                <div>
                                    <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                        <button 
                                            type="button" 
                                            class="btn" 
                                            :class="{'btn-primary': descripcionIdioma == 'ES', 'btn-secondary': descripcionIdioma != 'ES'}"
                                            @click="descripcionIdioma = 'ES'"
                                            :disabled = "loading"
                                        >Español</button>
                                        <button 
                                            type="button" 
                                            class="btn" 
                                            :class="{'btn-primary': descripcionIdioma == 'EN', 'btn-secondary': descripcionIdioma != 'EN' }"
                                            @click="descripcionIdioma = 'EN'"
                                            :disabled = "loading"
                                        >Ingles</button>
                                    </div>
                                </div>

                                <label class="mt-3">Desc. Corta</label>
                                <template>
                                    <div>
                                        <vue-editor id="editor1" v-if="descripcionIdioma == 'ES'" v-model="formdata.descripciones.ES.corta" :editor-toolbar="customToolbar" :disabled = "loading" :class="{'disable-text-bg-color': loading}"></vue-editor>
                                        <vue-editor id="editor2" v-if="descripcionIdioma == 'EN'" v-model="formdata.descripciones.EN.corta" :editor-toolbar="customToolbar" :disabled = "loading" :class="{'disable-text-bg-color': loading}"></vue-editor>
                                    </div>
                                </template>

                                <label class="mt-3">Desc. Larga</label>
                                <template>
                                    <div>
                                        <vue-editor id="editor3" v-if="descripcionIdioma == 'ES'" v-model="formdata.descripciones.ES.larga" :editor-toolbar="customToolbar" :disabled = "loading" :class="{'disable-text-bg-color': loading}"></vue-editor>
                                        <vue-editor id="editor4" v-if="descripcionIdioma == 'EN'" v-model="formdata.descripciones.EN.larga" :editor-toolbar="customToolbar" :disabled = "loading" :class="{'disable-text-bg-color': loading}"></vue-editor>
                                    </div>
                                </template>

                                <label class="mt-3">Keyfeatures</label>
                                <template>
                                    <div>
                                        <vue-editor id="editor5" v-if="descripcionIdioma == 'ES'" v-model="formdata.descripciones.ES.keyfeatures" :editor-toolbar="customToolbar" :disabled = "loading" :class="{'disable-text-bg-color': loading}"></vue-editor>
                                        <vue-editor id="editor6" v-if="descripcionIdioma == 'EN'" v-model="formdata.descripciones.EN.keyfeatures" :editor-toolbar="customToolbar" :disabled = "loading" :class="{'disable-text-bg-color': loading}"></vue-editor>
                                    </div>
                                </template>

                            </b-card>
                        </b-card-group>
                    </div>    

                </div>
                
                <div class="float-right mt-5">
                    <b-button type="reset" variant="danger" :disabled = "loading">Reset</b-button>
                    <b-button type="submit" variant="primary" class="ml-2" :disabled = "loading">Guardar</b-button>
                </div>
                
            </b-form>


        </b-card>
    </div>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import { VueEditor } from "vue2-editor";

export default {
    components: {
        VueTypeaheadBootstrap,
        VueEditor
    },
    data(){
        return {
            loading: false,
            queryCategoria: '',
            proveedores: [],
            categorias: [],
            descripcionIdioma:'ES',
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ],
            formdata: {
                id_proveedor: '',
                sku_proveedor:'',
                marca: '',
                modelo: '',
                titulo: '',
                link_item_proveedor: '',
                precio_lista: '',
                precio_lista_moneda: 'DOLAR',
                disponible: true,
                catidad_paquete: '1 Unidad',
                mini_img: '',
                arr_imgs: [{value: ''}],
                arr_videos: [],
                arr_attrs: [],
                categorias: [],
                manual: '',
                peso: {
                    value: '',
                    unit: 'kg'
                },
                codigo: {
                    value: '',
                    unit: 'GTIN'
                },
                medidas:{
                    x: {
                        value: '',
                        unit: 'cm'
                    },
                    y: {
                        value: '',
                        unit: 'cm'
                    },
                    z: {
                        value: '',
                        unit: 'cm'
                    },
                },
                stock_proveedor: 5,
                stock_dispo: 25,
                stock_proveedor_str: 'Entrega aproximada en 25 días',
                descripciones: {
                    'ES' : {
                        corta: '',
                        larga: '',
                        keyfeatures: ''
                    },
                    'EN' : {
                        corta: '',
                        larga: '',
                        keyfeatures: ''
                    },
                }
            },
            booleanOps: [{value: false, text: "No"},{value: true, text: "Si"}],
            idiomas: [{value: 'ES', text: "Español"},{value: 'EN', text: "Ingles"}],
            typeMonedas: [{value: 'DOLAR', text: "DOLAR"},{value: 'EURO', text: "EURO"},{value: 'ARG', text: "ARG"}],
            arrUnidadesMedidas: [ 
                { value: "U", text: "U"},
                { value: "km", text: "km"},
                { value: "m", text: "m"},
                { value: "cm", text: "cm"},
                { value: "mm", text: "mm"},
                { value: "µm", text: "µm"},
                { value: "nm", text: "nm"},
                { value: "yd", text: "yd"},
                { value: "ft", text: "ft"},
                { value: "in", text: "in"},
                { value: "\"", text: "\""},
                { value: "pulgadas", text: "pulgadas"},
                { value: "manos", text: "manos"}
            ],
            arrUnidadesPesos: [ 
                { value: "kg", text: "kg"},
                { value: "g", text: "g"},
                { value: "mg", text: "mg"},
                { value: "t", text: "t"},
                { value: "lb", text: "lb"},
                { value: "oz", text: "oz"},
                { value: "mcg", text: "mcg"},
            ],
            arrUnidadesCodigos: [ 
                { value: "GTIN", text: "GTIN"},
                { value: "UPC", text: "UPC"},
                { value: "EAN", text: "EAN"},
                { value: "ISBN", text: "ISBN"}
            ],
        }
    },
    computed: {
        arrCategorias() {
            let arr = [];
            this.categorias.forEach(element => {
                arr.push(element.nombre);
            });
            return arr;
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getProveedores(){
            try {
                let response = await this.$http.get('negocio/proveedor', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener proveedores');

                this.proveedores = [];
                response.body.data.forEach(element => {
                    this.proveedores.push({
                        value: element.id,
                        text: element.nombre
                    })
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getCategorias(id_padre){
            try {
                let query = '';
                if(id_padre) query += '?id_padre='+id_padre;
                let response = await this.$http.get('producto/categorias' + query, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener categorias');

                this.categorias = [];
                response.body.data.forEach(element => {
                    this.categorias.push({
                        id: element.id,
                        nombre: element.nombre
                    })
                });
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        addToTitle(){
           this.formdata.titulo = this.formdata.marca + ' ' + this.formdata.modelo;
        },
        agregarImagen(){
            this.formdata.arr_imgs.push({value: ''});
        },
        eliminarImagen(index){
            this.formdata.arr_imgs.splice(index, 1);
        },
        agregarVideo(){
            this.formdata.arr_videos.push({value: ''});
        },
        eliminarVideo(index){
            this.formdata.arr_videos.splice(index, 1);
        },
        setPesoUnit(pesoUnit){
            this.formdata.peso.unit = pesoUnit;
        },
        setCodigoUnit(codigoUnit){
            this.formdata.codigo.unit = codigoUnit;
        },
        setMedidaUnit(medida,medidaUnit){
            switch (medida) {
                case 'x':   this.formdata.medidas.x.unit = medidaUnit; 
                            this.formdata.medidas.y.unit = medidaUnit;
                            this.formdata.medidas.z.unit = medidaUnit;
                            break;
                case 'y':   this.formdata.medidas.y.unit = medidaUnit; break;
                case 'z':   this.formdata.medidas.z.unit = medidaUnit; break;
                default: break;
            }
        },
        agregarAtributo(){
            this.formdata.arr_attrs.push({key:'', value: ''});
        },
        eliminarAtributo(index){
            this.formdata.arr_attrs.splice(index, 1);
        },
        addToDispoStr(){
            this.formdata.stock_proveedor_str = 'Entrega aproximada en ' + this.formdata.stock_dispo + 'días' ;
        },
        async agregarCategoria(){
            if (this.queryCategoria && this.queryCategoria != ''){
                let value = this.queryCategoria.toString();
                value = value.charAt(0).toUpperCase() + value.slice(1);

                let categoria = this.categorias.find(element => element.nombre == value);
                if(categoria){
                    await this.getCategorias(categoria.id);
                }
                this.formdata.categorias.push(value);
                this.queryCategoria = '';
            } 
        },
        eliminarCategoria(index){
            this.formdata.categorias.splice(index, 1);
        },
        onReset(){
            this.formdata = {
                id_proveedor: '',
                sku_proveedor:'',
                marca: '',
                modelo: '',
                titulo: '',
                link_item_proveedor: '',
                precio_lista: '',
                precio_lista_moneda: 'DOLAR',
                disponible: true,
                catidad_paquete: '1 Unidad',
                mini_img: '',
                arr_imgs: [{value: ''}],
                arr_videos: [],
                arr_attrs: [],
                categorias: [],
                manual: '',
                peso: {
                    value: '',
                    unit: 'kg'
                },
                codigo: {
                    value: '',
                    unit: 'GTIN'
                },
                medidas:{
                    x: {
                        value: '',
                        unit: 'cm'
                    },
                    y: {
                        value: '',
                        unit: 'cm'
                    },
                    z: {
                        value: '',
                        unit: 'cm'
                    },
                },
                stock_proveedor: 5,
                stock_dispo: 25,
                stock_proveedor_str: 'Entrega aproximada en 25 días',
                descripciones: {
                    'ES' : {
                        corta: '',
                        larga: '',
                        keyfeatures: ''
                    },
                    'EN' : {
                        corta: '',
                        larga: '',
                        keyfeatures: ''
                    },
                }
            };
        },
        formatDataToSend(){

            let arr_imgs = [];
            this.formdata.arr_imgs.forEach(element => {
                arr_imgs.push(element.value)
            });
            let arr_videos = [];
            this.formdata.arr_videos.forEach(element => {
                arr_videos.push(element.value)
            });
            let arr_manuales = [];
            if(this.formdata.manual != '') arr_manuales.push(this.formdata.manual);
            let arr_codigos = [{
                item: 1,
                tipo: this.formdata.codigo.unit,
                codigo: this.formdata.codigo.value,
            }];
            let arr_pesos = [{
                item: 1,
                tipo: 'CAJA',
                peso: this.formdata.peso.value,
                aprox: false,
                peso_unit : this.formdata.peso.unit
            }];
            let arr_medidas = [{
                item: 1,
                tipo: 'CAJA',
                x: this.formdata.medidas.x.value,
                y: this.formdata.medidas.y.value,
                z: this.formdata.medidas.x.value,
                x_unit: this.formdata.medidas.x.unit,
                y_unit: this.formdata.medidas.y.unit,
                z_unit: this.formdata.medidas.z.unit,
                aprox: false,
            }];
            let arr_attrs = [];
            this.formdata.arr_attrs.forEach(element => {
                arr_attrs.push({
                    item: 1,
                    key: element.key,
                    value: element.value,
                    valor_unit: null
                })
            });

            let data = {
                id_proveedor : this.formdata.id_proveedor,
                sku_proveedor : this.formdata.sku_proveedor,
                link_item_proveedor : this.formdata.link_item_proveedor,
                stock_proveedor : this.formdata.stock_proveedor,
                stock_dispo : this.formdata.stock_dispo,
                stock_proveedor_str : this.formdata.stock_proveedor_str || `Entrega aproximada en ${this.formdata.stock_dispo} días`,
                precio_lista : this.formdata.precio_lista,
                precio_lista_moneda : this.formdata.precio_lista_moneda,
                disponible : this.formdata.disponible,
                categorias_proveedor : this.formdata.categorias,
                variations : [],
                marca : this.formdata.marca,
                modelo : this.formdata.modelo,
                titulo : this.formdata.titulo,
                mini_img : this.formdata.mini_img,
                catidad_paquete : this.formdata.catidad_paquete,
                arr_imgs,
                arr_videos,
                arr_manuales,
                arr_codigos,
                arr_pesos,
                arr_medidas,
                arr_attrs,
                descripciones: this.formdata.descripciones
            }

            return data;
        },
        validarData(){
            let message = null;
            if(!this.formdata.id_proveedor) message = 'Debe seleccionar un proveedor';
            if(!message && !this.formdata.sku_proveedor) message = 'Debe ingresar un sku';
            if(!message && !this.formdata.marca) message = 'Debe ingresar una marca';
            if(!message && !this.formdata.modelo) message = 'Debe ingresar un modelo';
            if(!message && !this.formdata.titulo) message = 'Debe ingresar un titulo';
            if(!message && !this.formdata.link_item_proveedor) message = 'Debe ingresar una url';
            if(!message && !this.formdata.precio_lista) message = 'Debe ingresar un precio';
            if(!message && !this.formdata.precio_lista_moneda) message = 'Debe seleccionar la moneda';
            if(!message && !this.formdata.mini_img) message = 'Debe seleccionar una imagen principal';
            if(!message && !this.formdata.categorias.length) message = 'Debe seleccionar al menos una categoria';
            if(!message && !this.formdata.peso.value) message = 'Debe seleccionar ingresar el peso';
            if(!message && (!this.formdata.medidas.x.value || !this.formdata.medidas.y.value || !this.formdata.medidas.z.value)) message = 'Debe seleccionar ingresar las medidas';
            if(!message && !this.formdata.stock_proveedor) message = 'Debe seleccionar el stock del proveedor';
            if(!message && !this.formdata.stock_dispo) message = 'Debe seleccionar la disposicion de stock del proveedor';
            if(!message && (!this.formdata.descripciones.ES.corta || !this.formdata.descripciones.ES.corta)) message = 'Debe completar las descripciones en español del articulo';

            if(message){
                this.showErrorModal(message);
                return false;
            }

            return true;
        },
        async onSubmit(){
            this.loading = true;
            console.log('onSubmit',this.formdata);

            try {
                if(this.validarData()){
                    
                    let datos = this.formatDataToSend();
                    let response = await this.$http.post('articulo/manual', datos , { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken }});
                    if(!response.body.success) throw new Error(response.body.err.message);
                    console.log('onSubmit response',response.body);
                    

                    this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Articulo creado',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }

                this.loading = false;
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                this.loading = false;
            }
            return true;            
        }
    },
    async beforeMount(){
        this.loading = true;
        await this.getProveedores();
        await this.getCategorias(null);
        this.loading = false;
    }
}
</script>

<style scoped>
    .disable-text-bg-color{
        background-color: #E9ECEF;
    }
</style>