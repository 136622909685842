<template>
    <div>
        <div v-if="loading" class="d-flex justify-content-center mt-5">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <div v-else>
            <b-card header-tag="header" >
                <template #header>
                    <div>
                        <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                            <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                        </b-button>
                        <div class="float-left table-panel-title mr-5">Categorias Fiscales</div>
                        <div class="float-right">
                            <b-button-group size="sm">
                                <b-button variant="primary" @click="agregar">Agregar</b-button>
                            </b-button-group>
                        </div>
                    </div>               
                </template>
                
                <div>
                    <b-table 
                        striped 
                        hover 
                        :items="categorias" 
                        :fields="table.fields"
                    >
                        <template #cell(acciones)="data">
                            <b-button-group>
                                <b-button variant="warning" @click="editar(data.item)" size="sm" class="mr-1"><b-icon icon="pencil"></b-icon></b-button>
                                <b-button variant="danger" @click="borrar(data.item)" size="sm"><b-icon icon="trash"></b-icon></b-button>
                            </b-button-group>
                        </template>

                    </b-table>
                </div>

            </b-card>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            table: {
                fields: [
                    {
                        key: 'categoria',
                        label: 'Categoria',
                        sortable: true
                    },
                    {
                        key: 'pa',
                        label: 'P. Arancelaria',
                        sortable: true
                    },
                    {
                        key: 'derechos',
                        label: 'Derechos',
                        sortable: true
                    },
                    {
                        key: 'iva',
                        label: 'IVA',
                        sortable: true
                    },
                    {
                        key: 'estadistica',
                        label: 'Estadistica',
                        sortable: true
                    },
                    {
                        key: 'acciones',
                        label: 'Acciones',
                        sortable: false
                    }
                ]
            },
            categorias: []
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getCategoriasFiscales(){
            try {
                let response = await this.$http.get('negocio/categoria-fiscal', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error(response.body.err.message);
                this.categorias = response.body.data;
                console.log('Categorias',this.categorias);
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        agregar(){
             this.$swal.fire({
                title: 'Agregar Categoria',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: `Cancelar`,
                confirmButtonText: `Guardar`,
                reverseButtons: true,
                html:
                    `<div class="input-group mb-3 mt-5">
                        <span class="input-group-text">Categoria</span>
                        <input type="text" class="form-control" id="categoria_add">
                    </div>`+
                    `<div class="input-group mb-3">
                        <span class="input-group-text">P. Arancelaria</span>
                        <input type="text" class="form-control" id="pa_add">
                    </div>`+
                    `<div class="input-group mb-3">
                        <span class="input-group-text">Derechos</span>
                        <input type="number" step="0.01" class="form-control" id="derechos_add">
                        <span class="input-group-text">%</span>
                    </div>`+
                    `<div class="input-group mb-3">
                        <span class="input-group-text">IVA</span>
                        <input type="number" step="0.01" class="form-control" id="iva_add">
                        <span class="input-group-text">%</span>
                    </div>`+
                    `<div class="input-group mb-3">
                        <span class="input-group-text">Estadistica</span>
                        <input type="number" step="0.01" class="form-control" id="estadistica_add">
                        <span class="input-group-text">%</span>
                    </div>`
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let datos = {
                        categoria: document.getElementById('categoria_add').value.trim(),
                        pa: document.getElementById('pa_add').value.trim(),
                        derechos: document.getElementById('derechos_add').value.trim(),
                        iva: document.getElementById('iva_add').value.trim(),
                        estadistica: document.getElementById('estadistica_add').value.trim(),
                    }
                    this.agregarCategoria(datos);
                }
            })
        },
        async agregarCategoria(datos){
            console.log('Agregar Categoria',datos);
            try {
                let response = await this.$http.post('negocio/categoria-fiscal', datos , { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken }});
                if(!response.body.success) throw new Error(response.body.err.message);

                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Categoria creada',
                    showConfirmButton: false,
                    timer: 1500
                });
                await this.getCategoriasFiscales();
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        editar(data){
            this.$swal.fire({
                title: 'Agregar Categoria',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: `Cancelar`,
                confirmButtonText: `Guardar`,
                reverseButtons: true,
                html:
                    `<div class="input-group mb-3 mt-5">
                        <span class="input-group-text">Categoria</span>
                        <input type="text" class="form-control" id="categoria_edit" value="${data.categoria}">
                    </div>`+
                    `<div class="input-group mb-3">
                        <span class="input-group-text">P. Arancelaria</span>
                        <input type="text" class="form-control" id="pa_edit" value="${data.pa}">
                    </div>`+
                    `<div class="input-group mb-3">
                        <span class="input-group-text">Derechos</span>
                        <input type="number" step="0.01" class="form-control" id="derechos_edit" value="${data.derechos}">
                        <span class="input-group-text">%</span>
                    </div>`+
                    `<div class="input-group mb-3">
                        <span class="input-group-text">IVA</span>
                        <input type="number" step="0.01" class="form-control" id="iva_edit" value="${data.iva}">
                        <span class="input-group-text">%</span>
                    </div>`+
                    `<div class="input-group mb-3">
                        <span class="input-group-text">Estadistica</span>
                        <input type="number" step="0.01" class="form-control" id="estadistica_edit" value="${data.estadistica}">
                        <span class="input-group-text">%</span>
                    </div>`
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let datos = {
                        id: data.id,
                        categoria: document.getElementById('categoria_edit').value.trim(),
                        pa: document.getElementById('pa_edit').value.trim(),
                        derechos: document.getElementById('derechos_edit').value.trim(),
                        iva: document.getElementById('iva_edit').value.trim(),
                        estadistica: document.getElementById('estadistica_edit').value.trim(),
                    }
                    this.editarCategoria(datos);
                }
            })
        },
        async editarCategoria(datos){
            try {
                let response = await this.$http.put('negocio/categoria-fiscal/'+datos.id, datos , { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken }});
                console.log("Rta edit Categoria: ",response);
                if(!response.body.success) throw new Error(response.body.err.message);

                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Categoria editada',
                    showConfirmButton: false,
                    timer: 1500
                });
                await this.getCategoriasFiscales();
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        borrar(data){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro de borrar la categoria?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Borrar',
                cancelButtonText: 'No',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.borrarCategoria(data);
                    }
                })
        },
        async borrarCategoria(datos){
            try {
                let response = await this.$http.delete('negocio/categoria-fiscal/'+datos.id, {} , { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken }});
                console.log("Rta del Categoria: ",response);
                if(!response.body.success) throw new Error(response.body.err.message);

                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Categoria borrada',
                    showConfirmButton: false,
                    timer: 1500
                });
                await this.getCategoriasFiscales();
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
    },
    async beforeMount(){
        this.loading = true;
        await this.getCategoriasFiscales();
        this.loading = false;
    }
}
</script>

<style scoped>

</style>